import React from 'react';
import { Material_picker_new, Add_from_store, Vertical_options, Horizontal_options, DeleteSvg, EditSvg, BuildingSvg, Isometric, Lineweight, Textures, Tiling_layout, Reflected_ceiling_plan, Skeleton_mode, View_Layers, Toolguide, Lock_view, Unlock_view, NewTabSvg, BookSvg, GlobeSvg, ChatSvg, YouTubeSvg, View_settings, Close_view, WorldSvg, FloorSvg, RoomSvg, CrossSvg, FilterSvg, MinimizeSvg, ChevronLeftSvg, InfoSvg, MaximizeSvg, MaterialSphereSvg, MaterialCubeSvg, ImagePlaceholderSvg, ExpandSvg, ItalicsSvg, BoldSvg, CenterAlignSvg, LeftAlignSvg, RightAlignSvg, RemoveSvg, AddSvg, FloorPlanSvg, CustomPlanSvg, ElevationViewSvg, UnderlineSvg, PaperClipSvg, UploadIcon, TrashIcon, LinkSvg, InfurniaSvg, NeedHelpSvg, BackSvg, FullscreenSvg, CrossThinSvg, TriangleDownSolidSvg, WarningSvg } from './Resources';

export const icons = {
    'edit': EditSvg,
    'delete': DeleteSvg,
    'isometric' : Isometric, 
    'textures' : Textures,
    'lineweight' : Lineweight,
    'tiling_layout' : Tiling_layout, 
    'reflected_ceiling_plan' : Reflected_ceiling_plan,
    'skeleton_mode' : Skeleton_mode,
    'tool_guide' : Toolguide,
    'material_picker_new': Material_picker_new,
    'add_from_store': Add_from_store,
    'vertical_options': Vertical_options,
    'horizontal_options': Horizontal_options,
    'lock' : Lock_view,
    'unlock': Unlock_view,
    'View_settings': View_settings,
    'view_layers': View_Layers,
    'close_view': Close_view,
    'new_tab': NewTabSvg,
    'book': BookSvg,
    'globe': GlobeSvg,
    'chat': ChatSvg,
    'youtube': YouTubeSvg,
    'building': BuildingSvg,
    'world': WorldSvg,
    'floor': FloorSvg,
    'room': RoomSvg,
    'cross': CrossSvg,
    'filter': FilterSvg,
    'minimize': MinimizeSvg,
    'chevron_left': ChevronLeftSvg,
    'info': InfoSvg,
    'maximize': MaximizeSvg,
    'material_sphere': MaterialSphereSvg,
    'material_cube': MaterialCubeSvg,
    'image_placeholder': ImagePlaceholderSvg,
    'paper_clip': PaperClipSvg,
    'upload_icon': UploadIcon,
    'trash_icon': TrashIcon,
    'link': LinkSvg,
    'italics': ItalicsSvg,
    'bold': BoldSvg,
    'underline': UnderlineSvg,
    'center_align': CenterAlignSvg,
    'left_align': LeftAlignSvg,
    'right_align': RightAlignSvg,
    'expand': ExpandSvg,
    'remove': RemoveSvg,
    'add': AddSvg,
    'floor_plan': FloorPlanSvg,
    'custom_plan': CustomPlanSvg,
    'elevation_view': ElevationViewSvg,
    'infurnia': InfurniaSvg,
    'need_help': NeedHelpSvg,
    'back': BackSvg,
    'fullscreen': FullscreenSvg,
    'cross_thin': CrossThinSvg,
    'triangle_down': TriangleDownSolidSvg,
    'warning': WarningSvg,
}

const Icons = ({ name, style, className, ...props }) => {
    const Icon = icons[name]; // Assuming all SVG icons are in the /icons folder

    if (Icon) {
        return (
            <div style={{ display: 'flex', ...style }} className={`infurnia_icon ${className ? className : ''}`} {...props}>
                <Icon />
            </div>)
    }

    return (
        ''
    );
};

export default Icons;
