import React , { useState , useEffect }from 'react';
//import { Alert, Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
//import { COLORS } from '../../utils/color';
import ErrorSVG from '../../alert_svgs/error.svg'
import SuccessSVG from '../../alert_svgs/success.svg'
import WarningSVG from '../../alert_svgs/warning.svg'
import HelpSVG from '../../alert_svgs/help.svg'
import InfoSVG from '../../alert_svgs/information.svg'
import { send_analytics } from '../../utils/send_analytics';
import {useTranslation} from 'react-i18next'

const AlertTemplate = ({ style, options, message, close}) => {
    const [is_short_alert, set_is_short_alert] = useState(false)
    const {t} = useTranslation()
	useEffect(() => {
		console.log('alert_message', message)
		if(message && message.length && Array.isArray(message)){
			if(message[0] && message[0].length<=40){
				set_is_short_alert(true)
			}
		}else if(message && message.length<=40){
			set_is_short_alert(true)
		}
		
	}, [message]);

	const onclick_button = async (route, arg) => {
        if(route=="new_window_link"){
            window.open(arg, '_blank');
        }else{
            if(route=="new_window_link"){
                var analytics_data = {}
                analytics_data.category = "View Render Image";
                analytics_data.route = "toast";
                send_analytics(analytics_data,true);
            }
            if(route=="open_post_processing_modal"){
                var analytics_data = {}
                analytics_data.category = "Edit Render Image";
                analytics_data.route = "toast";
                send_analytics(analytics_data,true);
            }
            if(route && arg != undefined){
                var promise_resp = await window.Promisify(window.Module[route](String(arg)))
                window.handle_ui_response(promise_resp)
                close()
            }
        }
	}

    return(
        <div style={style}>
            { 
                options.type==='info' && 
                <div id='alert_main_div' style={{backgroundColor:'#FFFFFF',display:'flex' ,color:'black', padding:'15px', margin:'20px', width:'400px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.25)', zIndex:'1000001'}}>
					<div style={{fontSize:'18px',}}><i style={{color:'#0747A6', marginRight:'15px', marginTop:'4px', marginLeft:'4px'}} className='fa fa-info-circle'/></div>
					<div style={{fontSize:'14px',flex:'8', display:'flex', flexDirection:'column'}}>
						<span style={{ fontSize:'16px',fontWeight:'600', marginTop:is_short_alert?'1px':'0px'}}>
                        {   
                            is_short_alert?
                                <div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>{message?(message && message.length && Array.isArray(message) ? message[0] : message):''}</div>
                                    <span onClick={close} style={{cursor:'pointer', fontWeight:'800', fontSize:'18px'}}>×</span>
                                 </div>
                                :<div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>{t(`Info`)}</div>
                                    <span onClick={close} style={{cursor:'pointer', fontWeight:'800', fontSize:'16px', marginTop:'-16px'}}>×</span>
                                 </div>
                        }
                        </span>
						{
							is_short_alert?'':<span>{message?(message && message.length && Array.isArray(message) ? message[0] : message):''}</span>
						}
					</div>
                </div>
            }
            { 
                options.type==='success' && 
                <div id='alert_main_div' style={{backgroundColor:'#CCF4E3',display:'flex' ,color:'black', padding:'15px', margin:'20px', width:'400px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.20)', zIndex:'1000001'}}>
					<div style={{fontSize:'18px',}}><i style={{color:'green', marginRight:'15px', marginTop:'4px', marginLeft:'4px'}} className='fa fa-check-circle'/></div>
					<div style={{fontSize:'14px',flex:'8', display:'flex', flexDirection:'column'}}>
						<span style={{ fontSize:'16px',fontWeight:'600', marginTop:is_short_alert?'1px':'0px'}}>
                        {   
                            is_short_alert?
                                <div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>{message?(message && message.length && Array.isArray(message) ? message[0] : message):''}</div>
                                    <span onClick={close} style={{cursor:'pointer', fontWeight:'800', fontSize:'18px'}}>×</span>
                                 </div>
                                :<div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>{t(`Success`)}</div>
                                    <span onClick={close} style={{cursor:'pointer', fontWeight:'800', fontSize:'16px', marginTop:'-16px'}}>×</span>
                                 </div>
                        }
                        </span>
						{
                            <span>
							{
                                is_short_alert?'':(<span>
                                {message?(message && message.length && Array.isArray(message) ? message[0] : message):''}
                                </span>)
                            }
                            {
                                options.click_here_action&&options.click_here_action.route&&options.click_here_action.message?(
                                    <span>. <span onClick={async () => {var ui_response = await window.Promisify(window.Module[options.click_here_action.route](""));window.handle_ui_response(ui_response);}} style={{cursor:"pointer",textDecoration:"underline",fontWeight:"bold"}}>Click here</span> to {options.click_here_action.message}</span>
                                ):''
                            }
                            </span>
						}
						<div className='flex_property mt-2'>
						{
							message && message.length && Array.isArray(message) ? message.map((x, idx) => (
								idx === 0 ? '' : <div className='mr-4 cp' onClick={() => onclick_button(x.route, x.arg)} style={{color:'#0078ff'}}>{x.name}</div>
							)) :""
						}
						</div>
					</div>
                </div>
            }
            { 
                options.type==='error' && 
                <div id='alert_main_div' style={{backgroundColor:'#FFEDEF',display:'flex' ,color:'black', padding:'15px', margin:'20px', width:'400px', borderRadius:'4px', boxShadow:'0px 4px 16px rgba(0, 0, 0, 0.20)', zIndex:'1000001'}}>
                    <div style={{fontSize:'18px',}}><img height='28px' width='28px' src={ErrorSVG} style={{ marginRight:'10px', marginLeft:'0px'}}/></div>
					<div style={{fontSize:'14px',flex:'8', display:'flex', flexDirection:'column'}}>
						<span style={{ fontSize:'16px',fontWeight:'600', marginTop:is_short_alert?'2px':'0px'}}>
                        {   
                            is_short_alert?
                                <div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>{message?(message && message.length && Array.isArray(message) ? message[0] : message):''}</div>
                                    <span onClick={close} style={{cursor:'pointer', fontWeight:'800', fontSize:'18px'}}>×</span>
                                 </div>
                                :<div className='flex_property' style={{justifyContent:"space-between"}}>
                                    <div>{t(`Error`)}</div>
                                    <span onClick={close} style={{cursor:'pointer', fontWeight:'800', fontSize:'16px', marginTop:'-16px'}}>×</span>
                                 </div>
                        }
                        </span>
						{
							is_short_alert?'':(<span>
                                {message?(message && message.length && Array.isArray(message) ? message[0] : message):''}
                                {options.click_here_action&&options.click_here_action.route&&options.click_here_action.message?(
                                    <span>. <span onClick={async () => {var ui_response = await window.Promisify(window.Module[options.click_here_action.route](""));window.handle_ui_response(ui_response);}} style={{cursor:"pointer",textDecoration:"underline",fontWeight:"bold"}}>Click here</span> to {options.click_here_action.message}</span>
                                ):''}
                            </span>)
						}
						<div className='flex_property mt-2'>
						{
							message && message.length && Array.isArray(message) ? message.map((x, idx) => (
								idx === 0 ? '' : <div className='mr-4 cp' onClick={() => onclick_button(x.route, x.arg)} style={{color:'#0078ff'}}>{x.name}</div>
							)) :""
						}
						</div>
					</div>
                </div>
            }
            { 
                options.type==='status' && 
                <div id='alert_main_div' style={{backgroundColor:"transparent",marginTop:120,fontSize:"12px",display:'flex' ,color:'black', padding:'15px', zIndex:'1000'}}>
                    <span style={{display:'flex', alignItems:'center', justifyContent:'flex-start', marginTop:'4px'}}>{message?message:"No Message"}</span>
                </div>
            }
        </div>
    )
}

AlertTemplate.defaultProps = {
    style : {},
    options: {},
};

export default AlertTemplate;