import React, { useState, useEffect, useRef } from 'react';
import { parse_expression_interface, validate_expression_interface, autofill_expression_interface } from '../utils/expression';
import { Tooltip} from 'antd';

const ExpressionInput = ({default_value, expression_available_parameters, on_update, style_override}) =>{
	const [parsed_expression, set_parsed_expression] = useState('');
	const [value, set_value] = useState('');
	const [is_valid, set_is_valid] = useState(true);
	const [suggestions, set_suggestions] = useState([]);
	const inputRef = useRef(null);
	const [preventBlur, set_preventBlur] = useState({is_valid: false});


	const inputOnBlur = (event) => {
		if (preventBlur.is_valid) {
			event.preventDefault(); 
			event.target.focus()
			const inputLength = event.target.value.length;
    		event.target.setSelectionRange(inputLength, inputLength);
			preventBlur.is_valid = false

		} else {
			on_update(value); set_suggestions([])
		}
	}

	const onMouseDownSuggestion = (event, suggestion) => {
		preventBlur.is_valid = true; 
		event.stopPropagation(); 
	}
	
	const onMouseUpSuggestion = (event, suggestion) => {
		event.stopPropagation(); 
		if(inputRef && inputRef.current){
			inputRef.current.value = value + suggestion;
		}
		set_value(value + suggestion);
		set_suggestions([])
	}

	useEffect(() => {
		set_value(default_value)
		set_parsed_expression(parse_expression_interface(default_value, expression_available_parameters))
	}, []);

	useEffect(() => {
		set_is_valid(validate_expression_interface(value, expression_available_parameters) === 'success')
		if(value){
			let last_element = String(value).split(' ')
			if(last_element && last_element.length){
				last_element = last_element[last_element.length - 1]
			}
			set_suggestions(JSON.parse(autofill_expression_interface(last_element, expression_available_parameters)).filter(x => x))
		}else{
			set_suggestions([])
		}
	}, [value]);


	useEffect(() => {
		// Position the suggestion list relative to the input element
		if(suggestions.length && inputRef.current) {
			const inputRect = inputRef.current.getBoundingClientRect();
			const left = inputRect.left;
			const top = inputRect.top + inputRect.height;
			const suggestionList = document.getElementById('suggestion-list');
			suggestionList.style.left = `${left}px`;
			suggestionList.style.top = `${top}px`;
		}
	}, [suggestions]);
	
	return (
		<div style={{ backgroundColor:"white", border: is_valid?"2px solid #CCF4E3":"2px solid #F9DADE", ...style_override }}>
			<div style={{display:"flex"}}>
				<Tooltip
					title={
						<div>
							{
								expression_available_parameters && Object.keys(expression_available_parameters).map(p => (
									<div className='flex_property mb-2'>
										<div className='mr-2'>
											{p}
										</div>
										<div>
											{expression_available_parameters[p]}
										</div>
									</div>
								))
							}
						</div>
					} 
					placement="right" mouseEnterDelay={1} overlayInnerStyle={{width:300}}>
					<img className={'pr-1 pl-2 '} src='/resources/icons/info.svg'></img>
				</Tooltip>
				<Tooltip title={parsed_expression} placement="bottom" mouseEnterDelay={1}>
					<input ref={inputRef} autoComplete='off' type="text" onChange={(e) => { set_value(e.target.value) }} onBlur={inputOnBlur} onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur() } }} size={Math.max(value.length, 3)} value={value}  style={{ border: 'none', flex: 5, height: '24px', minWidth:"min-content", outline: 'none' }} placeholder="0" />
				</Tooltip>
				<div style={{ width: '4px', backgroundColor: is_valid ? '#CCF4E3' : '#F9DADE',}}></div>
			</div>
			{suggestions.length > 0 && (
				<div className="suggestion-list" id="suggestion-list" style={{position:'fixed', zIndex: 3, background: 'white'}}>
					{suggestions.map((suggestion, index) => (
						<div className='single-suggestion' key={index} onMouseDown={(event) => {onMouseDownSuggestion(event, suggestion)}} onMouseUp={(event) => onMouseUpSuggestion(event, suggestion)}>
							{suggestion}
						</div>
					))}
				</div>
			)}
		</div>

	)
}

const image_cache = {
	loading:{},
	loaded:[]
}

const ConnectorCanvasComponent = ({curr_applicator, placement_style_idx, ui_action_callback, with_controller, canvas_width, canvas_height, panel_width, panel_depth, panel_thickness}) => {
    const canvasRef = useRef(null);

	const [activeElement, set_activeElement] = useState(null);

	const [activeTextBox, set_activeTextBox] = useState(null);

	const dimension_drop_in_mm = 20;


    useEffect(() => {
		draw_canvas();
	},[curr_applicator,canvas_width,canvas_height, activeElement])
	
	var distance_calc = (x1,y1,x2,y2) => {
		return Math.sqrt((x1 - x2)*(x1 - x2) + (y1 - y2)*(y1 - y2));
	}


	const findActiveElement = (clientX, clientY) => {
		var tol = 20;

		var new_activeElement = null;


		var type = curr_applicator.applicator_type;

		var connector_buttons = get_connector_buttons();

		if(new_activeElement == null){
			for(var i=0;i<connector_buttons.length;i++){
				var curr_button = connector_buttons[i];
				if(distance_calc(curr_button.x,curr_button.y,clientX,clientY) < curr_button.radius){
					new_activeElement = {
						object: curr_button.key,
						x: curr_button.x,
						y: curr_button.y
					}
					break;
				}
			}
		}

		var panel_origin = get_panel_origin();
		var pixel_per_mm = get_pixel_per_mm();
		var dimension_drop_amount = dimension_drop_amount_in_px();
		var half_thickness = (panel_thickness/2)*pixel_per_mm;

		var pt = {
			x: panel_origin.x,
			y: panel_origin.y + half_thickness
		}


		if(new_activeElement == null
			&& ((curr_applicator.application_method.includes("center_") && parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].repeat_distance) > 0) || (curr_applicator.application_method == "equal_distance"))
			&& with_controller && (type == "dowel_hinge")){
			var half_length = panel_width/2.0;
			var nearest_pos = half_length - parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].repeat_distance);

			var min_start_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_start_distance);
			var min_end_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_end_distance);;


			if(!new_activeElement){
				let dim_start = {x: pt.x,y: pt.y - dimension_drop_amount - half_thickness}
				let dim_end = {x: pt.x + pixel_per_mm*min_start_distance,y: pt.y - dimension_drop_amount - half_thickness}

				let text_pos = {
					x: (dim_start.x + dim_end.x)/2,
					y: (dim_start.y + dim_end.y)/2
				}

				let dist = distance_calc(text_pos.x, text_pos.y, clientX, clientY)

				if(dist < tol){
					new_activeElement = {
						object: "min_start_distance",
						x: text_pos.x,
						y: text_pos.y,
						val: curr_applicator.placement_styles[placement_style_idx][1].min_start_distance
					}
				}
			}

			if(!new_activeElement){
				let dim_start = {x: pt.x + pixel_per_mm*panel_width,y: pt.y - dimension_drop_amount - half_thickness}
				let dim_end = {x: pt.x + pixel_per_mm*(panel_width - min_end_distance),y: pt.y - dimension_drop_amount - half_thickness}

				let text_pos = {
					x: (dim_start.x + dim_end.x)/2,
					y: (dim_start.y + dim_end.y)/2
				}

				let dist = distance_calc(text_pos.x, text_pos.y, clientX, clientY)

				if(dist < tol){
					new_activeElement = {
						object: "min_end_distance",
						x: text_pos.x,
						y: text_pos.y,
						val: curr_applicator.placement_styles[placement_style_idx][1].min_end_distance
					}
				}
			}

			
			if(!new_activeElement && curr_applicator.application_method.includes("center_")){
				let dim_start = {x: pt.x + pixel_per_mm*nearest_pos, y: pt.y - dimension_drop_amount - half_thickness};
				let dim_end = {x: pt.x + pixel_per_mm*panel_width/2, y: pt.y - dimension_drop_amount - half_thickness};

				let text_pos = {
					x: (dim_start.x + dim_end.x)/2,
					y: (dim_start.y + dim_end.y)/2
				}

				let dist = distance_calc(text_pos.x, text_pos.y, clientX, clientY)

				if(dist < tol){
					new_activeElement = {
						object: "repeat_distance",
						x: text_pos.x,
						y: text_pos.y,
						val: curr_applicator.placement_styles[placement_style_idx][1].repeat_distance
					}
				}
			}
		}else if(new_activeElement == null && curr_applicator.application_method=="free" && with_controller && (type == "dowel_hinge")){
			var front_positions = get_filtered_offsets("front");
			var back_positions = get_filtered_offsets("back");
			var center_positions = get_filtered_offsets("center");

			

			for(var i=0;i<front_positions.length;i++){
				var dim_start = {x:pt.x,y:pt.y - (i+1)*dimension_drop_amount - half_thickness};
				var dim_end = {x:pt.x + pixel_per_mm*parse_offset_value(front_positions[i]),y:pt.y - (i+1)*dimension_drop_amount - half_thickness};

				var text_pos = {
					x: (dim_start.x + dim_end.x)/2,
					y: (dim_start.y + dim_end.y)/2
				}

				var dist = distance_calc(text_pos.x, text_pos.y, clientX, clientY)

				if(dist < tol){
					new_activeElement = {
						object: "offset",
						x: text_pos.x,
						y: text_pos.y,
						from: "front",
						curr_from: "front",
						idx: i,
						val: front_positions[i]
					}
				}
			}

			for(var i=0;i<back_positions.length;i++){
				var dim_start = {x:pt.x + pixel_per_mm*panel_width,y:pt.y + (i+1)*dimension_drop_amount + half_thickness};
				var dim_end = {x:pt.x + pixel_per_mm*panel_width - pixel_per_mm*parse_offset_value(back_positions[i]),y:pt.y + (i+1)*dimension_drop_amount + half_thickness};

				var text_pos = {
					x: (dim_start.x + dim_end.x)/2,
					y: (dim_start.y + dim_end.y)/2
				}

				var dist = distance_calc(text_pos.x, text_pos.y, clientX, clientY)

				if(dist < tol){
					new_activeElement = {
						object: "offset",
						x: text_pos.x,
						y: text_pos.y,
						from: "back",
						curr_from: "back",
						idx: i,
						val: back_positions[i]
					}
				}
			}
			
			var center_left_connectors_count = center_positions.filter(o => parse_offset_value(o) < 0).length;
			var center_right_connectors_count = 0;

			for(var i=0;i<center_positions.length;i++){
				var dim_start = {x:pt.x + (pixel_per_mm*panel_width/2),y:pt.y};
				var dim_end = {x:pt.x + (pixel_per_mm*panel_width/2) + pixel_per_mm*parse_offset_value(center_positions[i]),y:pt.y};

				var current_count;
				if(dim_start.x < dim_end.x){
					center_right_connectors_count += 1;
					current_count = center_right_connectors_count;
					dim_start.y -= ((current_count*dimension_drop_amount) + half_thickness)
					dim_end.y -= ((current_count*dimension_drop_amount) + half_thickness)
				}else{
					center_left_connectors_count -= 1;
					current_count = center_left_connectors_count+1;
					dim_start.y -= ((current_count*dimension_drop_amount) + half_thickness)
					dim_end.y -= ((current_count*dimension_drop_amount) + half_thickness)
				}

				var text_pos = {
					x: (dim_start.x + dim_end.x)/2,
					y: (dim_start.y + dim_end.y)/2
				}

				var dist = distance_calc(text_pos.x, text_pos.y, clientX, clientY)

				if(dist < tol){
					new_activeElement = {
						object: "offset",
						x: text_pos.x,
						y: text_pos.y,
						from: "center",
						curr_from: "center",
						idx: i,
						val: center_positions[i]
					}
				}
			}
		}else if(new_activeElement == null && curr_applicator.application_method=="free" && with_controller && (type == "single_panel_connector" || type == "cabinet_connector")){
			var back_left_positions = get_filtered_offsets("back","left");
			var back_right_positions = get_filtered_offsets("back","right");
			var front_right_positions = get_filtered_offsets("front","right");
			var front_left_positions = get_filtered_offsets("front","left");

			var panel_origin = get_panel_origin();
			var pixel_per_mm = get_pixel_per_mm();

			var back_left_idx = 0; var back_right_idx = 0; var front_right_idx = 0; var front_left_idx = 0;
			for(var i=0;i<curr_applicator.placement_styles[placement_style_idx][1].offsets.length;i++){
				var p, p_hori, p_vert;
				var curr_idx = 0;
				if(curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from == "back" && curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1 == "left"){
					curr_idx = back_left_idx;
					p = {x: panel_origin.x + pixel_per_mm*parse_offset_value(back_left_positions[curr_idx].x), y: panel_origin.y + pixel_per_mm*parse_offset_value(back_left_positions[curr_idx].y)};
					p_hori = {x: panel_origin.x, y: p.y};
					p_vert = {x: p.x, y: panel_origin.y};
					back_left_idx += 1;
				}else if(curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from == "back" && curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1 == "right"){
					curr_idx = back_right_idx;
					p = {x: panel_origin.x + pixel_per_mm*(panel_width - parse_offset_value(back_right_positions[curr_idx].x)),y: panel_origin.y + pixel_per_mm*parse_offset_value(back_right_positions[curr_idx].y)};
					p_hori = {x: panel_origin.x + pixel_per_mm*panel_width, y: p.y};
					p_vert = {x: p.x, y: panel_origin.y};
					back_right_idx += 1;
				}else if(curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from == "front" && curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1 == "right"){
					curr_idx = front_right_idx;
					p = {x: panel_origin.x + pixel_per_mm*(panel_width - parse_offset_value(front_right_positions[curr_idx].x)), y: panel_origin.y + pixel_per_mm*(panel_depth - parse_offset_value(front_right_positions[curr_idx].y))}
					p_hori = {x: panel_origin.x + pixel_per_mm*panel_width, y: p.y};
					p_vert = {x: p.x, y: panel_origin.y + pixel_per_mm*panel_depth};
					front_right_idx += 1;
				}else if(curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from == "front" && curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1 == "left"){
					curr_idx = front_left_idx;
					p = {x: panel_origin.x + pixel_per_mm*parse_offset_value(front_left_positions[curr_idx].x), y: panel_origin.y + pixel_per_mm*(panel_depth - parse_offset_value(front_left_positions[curr_idx].y))}
					p_hori = {x: panel_origin.x, y: p.y};
					p_vert = {x: p.x, y: panel_origin.y + pixel_per_mm*panel_depth};
					front_left_idx += 1;
				}

				
				
				if(p){
					var dist_hori = distance_calc((p.x + p_hori.x)/2, (p.y + p_hori.y)/2, clientX, clientY);

					if(dist_hori < tol){
						new_activeElement = {
							object: "offset",
							x: (p.x + p_hori.x)/2,
							y: (p.y + p_hori.y)/2,
							from: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from,
							from1: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1,
							curr_from: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1,
							idx: curr_idx,
							val: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].distance1
						}
					}

					var dist_vert = distance_calc((p.x + p_vert.x)/2, (p.y + p_vert.y)/2, clientX, clientY);
					if(dist_vert < tol){
						new_activeElement = {
							object: "offset",
							x: (p.x + p_vert.x)/2,
							y: (p.y + p_vert.y)/2,
							from: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from,
							from1: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1,
							curr_from: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from,
							idx: curr_idx,
							val: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].distance
						}
					}
				}
			}
		}
		return new_activeElement;
	}

	const active_text_keyup = (new_value) => {
		if(activeTextBox && (activeTextBox.object == 'offset' || activeTextBox.object == 'repeat_distance' || activeTextBox.object == 'min_start_distance' || activeTextBox.object == 'min_end_distance')){
			var params = { panel_width, panel_depth, panel_thickness, total_length: panel_width };
			if(isNaN(Number(new_value)) && (validate_expression_interface(new_value, params) == "failure")){
				set_activeTextBox({
					...activeTextBox,
					is_entered_text_invalid: true
				}) 
				//invalid entry
			}else{
				ui_action_callback({
					...activeTextBox,
					val: new_value,
					placement_style_idx
				});
				set_activeTextBox(null);
			}
		}
	}
	

	const mousemove = (e) => {
		if(!with_controller){
			return;
		}
		var canvas = canvasRef.current;

		if(!canvas){
			return;
		}

		var clientX = e.clientX - canvas.getBoundingClientRect().x;
		var clientY = e.clientY - canvas.getBoundingClientRect().y;

		if(!activeTextBox){
			var new_activeElement = findActiveElement(clientX, clientY)
			set_activeElement(new_activeElement);
		}
	}

	const mouseup = (e) => {
		if(!with_controller){
			return;
		}
		var canvas = canvasRef.current;

		if(!canvas){
			return;
		}

		var context = canvas.getContext('2d');

		var clientX = e.clientX - canvas.getBoundingClientRect().x;
		var clientY = e.clientY - canvas.getBoundingClientRect().y;

		var new_activeElement = findActiveElement(clientX, clientY);
		if(new_activeElement){
			if(new_activeElement.object == "offset" || new_activeElement.object == "repeat_distance" || new_activeElement.object == "min_start_distance" || new_activeElement.object == "min_end_distance"){
				set_activeTextBox({
					...new_activeElement,
					x: canvas.getBoundingClientRect().x + new_activeElement.x,
					y: canvas.getBoundingClientRect().y + new_activeElement.y,
				});
			}else {
				set_activeTextBox(null);
				ui_action_callback({...new_activeElement, placement_style_idx});
			}
		}else{
			set_activeTextBox(null);
		}
	}

	const parse_offset_value = (val) => {
		if(!val){
			return 0;
		}

		var params = { panel_width, panel_depth, panel_thickness, total_length: panel_width };

		var new_val = 0;
		if(!isNaN(Number(val))){
			new_val = Number(val);
		}else{
			var is_valid = (validate_expression_interface(String(val), params) == "success");
			if(is_valid){
				new_val = parse_expression_interface(String(val), params);
			}
		}

		return new_val;
	}

	const get_filtered_offsets = (from_value, from_value1) => {
		//returns array of numbers if only 1D is passed. otherwise returns points
		var result = [];
		

		//for hinges
		var alternate_from_values = {
			"front": "top",
			"back": "bottom"
		}

		for(var i=0;i<curr_applicator.placement_styles[placement_style_idx][1].offsets.length;i++){
			var condition = (!from_value) || (from_value && curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from == from_value) || (!from_value1 && from_value && curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from == alternate_from_values[from_value]);
			var condition1 = (!from_value1) || (from_value1 && curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1 == from_value1);
			if(condition && condition1){
				if(from_value && from_value1){
					result.push({
						x: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].distance1,
						y: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].distance
					})
				}else{
					result.push(curr_applicator.placement_styles[placement_style_idx][1].offsets[i].distance)
				}
			}
		}

		return result;
	}

	const get_filtered_indices = (from_value, from_value1) => {
		//returns indices of the connector in main offsets array
		var result = [];

		for(var i=0;i<curr_applicator.placement_styles[placement_style_idx][1].offsets.length;i++){
			var condition = (!from_value) || (from_value && curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from == from_value);
			var condition1 = (!from_value1) || (from_value1 && curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1 == from_value1);
			if(condition && condition1){
				result.push(i);
			}
		}

		return result;
	}

    const drawConnectorCursor = (context, centerX, centerY, radius, strokeColor, strokeWidth, opacity = 1) => {
		var inner_circle_radius = radius/3;

		context.lineWidth = strokeWidth;
		context.strokeStyle = strokeColor;
		context.fillStyle = "#ffffff";

		context.globalAlpha = opacity;

		context.beginPath();
		context.arc(centerX, centerY, inner_circle_radius, 0, 2 * Math.PI, false);
		context.fill();
		context.stroke();

		context.beginPath();
		
		context.moveTo(centerX, centerY - inner_circle_radius);
		context.lineTo(centerX, centerY - radius);
		context.moveTo(centerX + inner_circle_radius, centerY);
		context.lineTo(centerX + radius, centerY);
		context.moveTo(centerX, centerY + inner_circle_radius);
		context.lineTo(centerX, centerY + radius);
		context.moveTo(centerX - inner_circle_radius, centerY);
		context.lineTo(centerX - radius, centerY);

		context.stroke();

		context.globalAlpha = 1;
	}

	const draw_dimension = (context, drop_height, p1,p2, text_to_show, text_color, dotted = false, force_show_text = false) => {
		var angle = Math.atan2(p2.y - p1.y, p2.x - p1.x);
		var drop_height_x = Math.cos(angle - (Math.PI/2))*drop_height;
		var drop_height_y = Math.sin(angle - (Math.PI/2))*drop_height;

		context.textAlign = "center"
		context.textBaseline = "middle";
		context.fillStyle = text_color;

		var is_text_pure_number = !isNaN(Number(text_to_show));
		var text = is_text_pure_number || text_to_show=="EQ" || force_show_text?text_to_show:"fx"

		var text_dim = context.measureText(text)


		context.save();
		if(dotted){
			context.setLineDash([5, 5]);
		}
		context.strokeStyle = "#0a0a0a";
		context.lineWidth = 1;
		context.beginPath();

		var actual_distance = Math.sqrt((p1.x - p2.x)*(p1.x - p2.x) + (p1.y - p2.y)*(p1.y - p2.y));
		if(actual_distance > 0.1){
			context.moveTo(p1.x + drop_height_x/4, p1.y + drop_height_y/4);
			context.lineTo(p1.x + drop_height_x, p1.y + drop_height_y);
			context.moveTo(p2.x + drop_height_x/4, p2.y + drop_height_y/4);
			context.lineTo(p2.x + drop_height_x, p2.y + drop_height_y);
		}

		var available_length = distance_calc(p1.x, p1.y, p2.x, p2.y);

		var final_p1 = {x: p1.x + drop_height_x, y: p1.y + drop_height_y};
		var final_p2 = {x: p2.x + drop_height_x, y: p2.y + drop_height_y};
		
		//leaving true boolean. else is drawing correctly but not handled in findActiveElement
		if(true || available_length > text_dim.width){
			var center = {x: (final_p1.x + final_p2.x)/2, y: (final_p1.y + final_p2.y)/2};
			if(actual_distance > 0.1){
				context.moveTo(final_p1.x, final_p1.y);
				context.lineTo(center.x - Math.cos(angle)*text_dim.width/2, center.y - Math.sin(angle)*text_dim.width/2);
				context.moveTo(center.x + Math.cos(angle)*text_dim.width/2, center.y + Math.sin(angle)*text_dim.width/2);
				context.lineTo(final_p2.x, final_p2.y);
			}

			context.fillText(text, center.x,  center.y);
		}else{
			if(actual_distance > 0.1){
				context.moveTo(final_p1.x, final_p1.y);
				context.lineTo(final_p2.x, final_p2.y);
				context.moveTo(final_p1.x, final_p1.y);
				context.lineTo(final_p1.x - Math.cos(angle)*drop_height, final_p1.y - Math.sin(angle)*drop_height);
			}
			context.fillText(text, final_p1.x - Math.cos(angle)*(20 + text_dim.width/2), final_p1.y - Math.sin(angle)*20);
		}


		context.stroke();
		context.restore();
	}

	const dimension_drop_amount_in_px = () => {
		var pixel_per_mm = get_pixel_per_mm();
		var dimension_drop_amount = dimension_drop_in_mm*pixel_per_mm;
		if(with_controller){
			dimension_drop_amount = dimension_drop_amount*0.9;
		}

		return dimension_drop_amount;
	}

	const get_pixel_per_mm = () => {
		return (0.7*canvas_width)/panel_width;
	}

	const get_panel_origin = () => {
		var pixel_per_mm = get_pixel_per_mm();

		if(curr_applicator.applicator_type == "dowel_hinge"){
			return {
				x: (canvas_width/2) - (panel_width/2)*pixel_per_mm,
				y: (canvas_height/2) - (panel_thickness/2)*pixel_per_mm
			}
		}else if(curr_applicator.applicator_type == "single_panel_connector" || curr_applicator.applicator_type == "cabinet_connector"){
			return {
				x: (canvas_width/2) - (panel_width/2)*pixel_per_mm,
				y: (canvas_height/2) - (panel_depth/2)*pixel_per_mm
			}
		}
	}

	const get_connector_buttons = () => {
		var buttons = []
		var panel_origin = get_panel_origin();
		var pixel_per_mm = get_pixel_per_mm();
		var type = curr_applicator.applicator_type;

		var panel_thickness_in_px = pixel_per_mm*(panel_thickness);
		// var button_radius = panel_thickness_in_px/3;
		var button_radius = canvas_width/500 * 6;

		if((curr_applicator.application_method=="free" || curr_applicator.application_method=="equal_distance") && with_controller && (type == "dowel_hinge")){
			var front_positions = [];
			var back_positions = [];
			var center_positions = [];

			if(curr_applicator.application_method=="equal_distance" && curr_applicator.placement_styles[placement_style_idx][1].offsets.length>0){
				// var diff = Math.floor(panel_width/(2*curr_applicator.placement_styles[placement_style_idx][1].offsets.length));
				// var last_equi_pos = diff;
				// for(var i=0;i<curr_applicator.placement_styles[placement_style_idx][1].offsets.length;i++){
				// 	front_positions.push(last_equi_pos);
				// 	last_equi_pos += 2*diff;
				// }

				var min_start_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_start_distance);
				var min_end_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_end_distance);

				var available_length = panel_width - min_start_distance - min_end_distance;
				var number_of_offsets = curr_applicator.placement_styles[placement_style_idx][1].offsets.length;
				if(number_of_offsets == 1){
					front_positions = [panel_width/2];
				}else{
					front_positions = [min_start_distance];
					var middle_offsets_to_add = number_of_offsets - 2;
					var gap_diff = available_length/(middle_offsets_to_add + 1);

					for(var n=0;n<middle_offsets_to_add;n++){
						front_positions.push(min_start_distance + gap_diff*(n+1));
					}

					front_positions.push(panel_width - min_end_distance);
				}
			}else{
				front_positions = front_positions.concat(get_filtered_offsets("front"));
				back_positions = back_positions.concat(get_filtered_offsets("back"));
				center_positions = center_positions.concat(get_filtered_offsets("center"));
			}

			front_positions.sort((a,b) => parse_offset_value(a) - parse_offset_value(b))
			back_positions.sort((a,b) => parse_offset_value(a) - parse_offset_value(b))
			center_positions.sort((a,b) => parse_offset_value(a) - parse_offset_value(b))

			var dimension_drop_amount = dimension_drop_amount_in_px();

			if(curr_applicator.application_method!="equal_distance"){
				var last_front_pos = front_positions.length>0?parse_offset_value(front_positions[front_positions.length-1]):0;
				var last_back_pos = back_positions.length>0?parse_offset_value(back_positions[back_positions.length-1]):0;
				var last_center_pos = center_positions.length>0?parse_offset_value(center_positions[center_positions.length-1]):(-(4*button_radius));

				if(center_positions.length>0){
					//want add button always on the right
					last_center_pos = Math.max(last_center_pos, 0);
				}

				buttons.push({
					x: panel_origin.x + last_front_pos*pixel_per_mm + (2*button_radius),
					y: panel_origin.y - Math.max(front_positions.length, 1)*dimension_drop_amount,
					radius: button_radius,
					key: "add_button_front",
					display: "+",
					text: "Add",
					text_placement: "right",
					color:"#4597f7"
				})
				if(front_positions.length>0){
					buttons.push({
						x: panel_origin.x + last_front_pos*pixel_per_mm + (2*button_radius),
						y: panel_origin.y - Math.max(front_positions.length, 1)*dimension_drop_amount - dimension_drop_amount - button_radius,
						radius: button_radius,
						key: "remove_button_front",
						display: "-",
						text: "Remove",
						text_placement: "right",
						color:"#ff0000"
					})
				}

				
				buttons.push({
					x: panel_origin.x + panel_width*pixel_per_mm - last_back_pos*pixel_per_mm - (2*button_radius),
					y: panel_origin.y + (panel_thickness_in_px) + Math.max(back_positions.length, 1)*dimension_drop_amount,
					radius: button_radius,
					key: "add_button_back",
					display: "+",
					text: "Add",
					text_placement: "left",
					color:"#4597f7"
				})


				if(back_positions.length>0){
					buttons.push({
						x: panel_origin.x + panel_width*pixel_per_mm - last_back_pos*pixel_per_mm - (2*button_radius),
						y: panel_origin.y + (panel_thickness_in_px) + Math.max(back_positions.length, 1)*dimension_drop_amount + dimension_drop_amount + button_radius,
						radius: button_radius,
						key: "remove_button_back",
						display: "-",
						text: "Remove",
						text_placement: "left",
						color:"#ff0000"
					})
				}


				var number_of_right_positions = center_positions.filter((val) => parse_offset_value(val) > 0).length;


				buttons.push({
					x: panel_origin.x + (panel_width*pixel_per_mm/2) + last_center_pos*pixel_per_mm + (2*button_radius),
					y: panel_origin.y - Math.max(number_of_right_positions, 1)*dimension_drop_amount,
					radius: button_radius,
					key: "add_button_center",
					display: "+",
					text: "Add",
					text_placement: "right",
					color:"#4597f7"
				})
				if(center_positions.length>0){
					buttons.push({
						x: panel_origin.x + (panel_width*pixel_per_mm/2) + last_center_pos*pixel_per_mm + (2*button_radius),
						y: panel_origin.y - Math.max(number_of_right_positions, 1)*dimension_drop_amount - dimension_drop_amount - button_radius,
						radius: button_radius,
						key: "remove_button_center",
						display: "-",
						text: "Remove",
						text_placement: "right",
						color:"#ff0000"
					})
				}
				
				


			}else{
				buttons.push({
					x: panel_origin.x + (panel_width/2)*pixel_per_mm,
					y: panel_origin.y + panel_thickness_in_px + dimension_drop_amount,
					radius: button_radius,
					key: "add_button_front",
					display: "+",
					text: "Add",
					text_placement: "right",
					color:"#4597f7"
				})
				if(front_positions.length>0){
					buttons.push({
						x: panel_origin.x + (panel_width/2)*pixel_per_mm,
						y: panel_origin.y + panel_thickness_in_px + (2*dimension_drop_amount) + button_radius,
						radius: button_radius,
						key: "remove_button_front",
						display: "-",
						text: "Remove",
						text_placement: "right",
						color:"#ff0000"
					})
				}
			}
		}else if(curr_applicator.application_method=="free" && with_controller && (type == "single_panel_connector" || type == "cabinet_connector")){
			buttons.push({
				x: panel_origin.x - (2*button_radius),
				y: panel_origin.y,
				radius: button_radius,
				key: "add_button_back_left",
				text: "Add",
				text_placement: "left",
				display: "+",
				color:"#4597f7"
			});
			buttons.push({
				x: panel_origin.x + (2*button_radius) + panel_width*pixel_per_mm,
				y: panel_origin.y,
				radius: button_radius,
				key: "add_button_back_right",
				text: "Add",
				text_placement: "right",
				display: "+",
				color:"#4597f7"
			});
			buttons.push({
				x: panel_origin.x + (2*button_radius) + panel_width*pixel_per_mm,
				y: panel_origin.y + pixel_per_mm*panel_depth,
				radius: button_radius,
				key: "add_button_front_right",
				text: "Add",
				text_placement: "right",
				display: "+",
				color:"#4597f7"
			});
			buttons.push({
				x: panel_origin.x - (2*button_radius),
				y: panel_origin.y + pixel_per_mm*panel_depth,
				radius: button_radius,
				key: "add_button_front_left",
				text: "Add",
				text_placement: "left",
				display: "+",
				color:"#4597f7"
			});

			for(var i=0;i<curr_applicator.placement_styles[placement_style_idx][1].offsets.length;i++){
				var curr_from = curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from;
				var curr_from1 = curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1;
				var curr_x = pixel_per_mm*parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].offsets[i].distance1);
				var curr_y = pixel_per_mm*parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].offsets[i].distance);
				if(curr_from == "front"){
					curr_y = panel_depth*pixel_per_mm - curr_y
				}
				
				if(curr_from1 == "right"){
					curr_x = panel_width*pixel_per_mm - curr_x - (3*button_radius)
				}else{
					curr_x += (3*button_radius)
				}

				buttons.push({
					x: panel_origin.x + curr_x,
					y: panel_origin.y + curr_y,
					radius: button_radius,
					key: "remove_button_index_" + i,
					display: "-",
					color:"#ff0000"
				});
			}

			button_radius *= 2;
			
			if(type == "single_panel_connector"){
				for(var i=0;i<curr_applicator.placement_styles[placement_style_idx][1].offsets.length;i++){
					var curr_from = curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from;
					var curr_from1 = curr_applicator.placement_styles[placement_style_idx][1].offsets[i].from1;
					var curr_x = pixel_per_mm*parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].offsets[i].distance1);
					var curr_y = pixel_per_mm*parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].offsets[i].distance);

					if(curr_from1 == "right"){
						curr_x = panel_width*pixel_per_mm - curr_x
					}

					if(curr_from == "front"){
						curr_y = panel_depth*pixel_per_mm - curr_y - (2*button_radius)
					}else{
						curr_y += (2*button_radius)
					}

					buttons.push({
						x: panel_origin.x + curr_x,
						y: panel_origin.y + curr_y,
						radius: button_radius,
						key: "switch_face_" + i,
						display: curr_applicator.placement_styles[placement_style_idx][1].offsets[i].panel_face=="top"?"/resources/icons/switch_face_top_to_bottom.svg":"/resources/icons/switch_face_bottom_to_top.svg",
						color:"#0000ff"
					});
				}
			}

		}
			

		return buttons
	}

	const drawCircledText = (context, text, pos_x, pos_y, radius, color) => {
		context.save();

		if(text.includes(".svg")){
			//text is svg url to draw inside the circle
			if(image_cache.loaded[text]){
				context.drawImage(image_cache.loaded[text], pos_x - radius/1.2, pos_y - radius/1.2, radius*2/1.2, radius*2/1.2);
			}else if(!(image_cache.loading[text])){
				image_cache.loading[text] = true;
				var img = new Image();
				img.src = text;
				img.onload = function(){
					image_cache.loaded[text] = img;
					draw_canvas();
				}
			}
		}else{
			context.font = "bold 12px sans-serif";
			// context.font = "bold " + (radius*2).toFixed(0) + "px sans-serif";
			context.fillStyle= "#ffffff";
			context.strokeStyle = color;
			context.textBaseline = "middle";
			context.beginPath();
			context.arc(pos_x, pos_y, radius, 0, 2 * Math.PI, false);
			context.fill();
			context.stroke();

			context.fillStyle = color;
			context.textAlign = "center";
			context.fillText(text, pos_x, pos_y);
		}


		context.restore();
	}

	const draw_canvas = () => {
		if(!curr_applicator){
			console.log("no connector to draw");
            return;
        }
		
        if(!canvasRef || !(canvasRef.current)){
			console.log("could not find canvas");
            return;
        }

		// console.log("drawing with ", _deref(curr_applicator))
		var canvas = canvasRef.current;

		if(!canvas){
			return;
		}
		var context = canvas.getContext('2d');

		
		var w = canvas.width;
		var h = canvas.height;

		var panel_origin = get_panel_origin();
		var pixel_per_mm = get_pixel_per_mm();


		// var target_font_size = ((panel_thickness)*pixel_per_mm);
		// if(with_controller){
		// 	target_font_size = target_font_size*0.7
		// }

		var target_font_size = canvas_width/500*12;

		context.font = (target_font_size).toFixed(0) + "px sans-serif";


		context.clearRect(0, 0, w, h);
		context.fillStyle = "#ffffff"
		context.strokeStyle = "#000000"
		
		var type = curr_applicator.applicator_type;

		if(type == "dowel_hinge"){
			context.fillRect(panel_origin.x, panel_origin.y, pixel_per_mm*panel_width, pixel_per_mm*panel_thickness);
			context.strokeRect(panel_origin.x, panel_origin.y, pixel_per_mm*panel_width, pixel_per_mm*panel_thickness);
		}else if(type == "single_panel_connector" || type == "cabinet_connector"){
			context.fillRect(panel_origin.x, panel_origin.y, pixel_per_mm*panel_width, pixel_per_mm*panel_depth);
			context.strokeRect(panel_origin.x, panel_origin.y, pixel_per_mm*panel_width, pixel_per_mm*panel_depth);
		}

		if(type == "single_panel_connector"){
			//fillText "Front" at bottom center, "Left" at left center, "Right" at right center, "Back" at top center. text should be outside the main rectangle with 10px gap
			//left and right text should be vertical text
			context.fillStyle = "rgba(70,70,70,0.5)";
			context.textAlign = "center";
			context.textBaseline = "middle";
			context.font = (target_font_size - 1).toFixed(0) + "px sans-serif";
			context.fillText("Front", panel_origin.x + pixel_per_mm*panel_width/2, panel_origin.y + pixel_per_mm*panel_depth + 10);
			context.fillText("Back", panel_origin.x + pixel_per_mm*panel_width/2, panel_origin.y - 10);
			context.save();
			context.translate(panel_origin.x - 10, panel_origin.y + pixel_per_mm*panel_depth/2);
			context.rotate(-Math.PI/2);
			context.fillText("Left", 0, 0);
			context.restore();
			context.save();
			context.translate(panel_origin.x + pixel_per_mm*panel_width + 10, panel_origin.y + pixel_per_mm*panel_depth/2);
			context.rotate(Math.PI/2);
			context.fillText("Right", 0, 0);
			context.restore();
			context.font = (target_font_size).toFixed(0) + "px sans-serif";
		}


		if(type == "dowel_hinge"){
			var front_positions = [];
			var back_positions = [];
			var center_positions = [];

			var dimension_drop_amount = dimension_drop_amount_in_px();


			if(curr_applicator.application_method == "free"){
				front_positions = front_positions.concat(get_filtered_offsets("front"));
				back_positions = back_positions.concat(get_filtered_offsets("back"));
				center_positions = center_positions.concat(get_filtered_offsets("center"));
			}else if(curr_applicator.application_method == "equal_distance" && curr_applicator.placement_styles[placement_style_idx][1].offsets.length>0){
				var min_start_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_start_distance);
				var min_end_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_end_distance);

				var available_length = panel_width - min_start_distance - min_end_distance;
				var number_of_offsets = curr_applicator.placement_styles[placement_style_idx][1].offsets.length;
				if(number_of_offsets == 1){
					front_positions = [min_start_distance + (available_length/2.0)];
				}else{
					front_positions = [min_start_distance];
					var middle_offsets_to_add = number_of_offsets - 2;
					var gap_diff = available_length/(middle_offsets_to_add + 1);

					for(var n=0;n<middle_offsets_to_add;n++){
						front_positions.push(min_start_distance + gap_diff*(n+1));
					}

					front_positions.push(panel_width - min_end_distance);
				}
			}else if(curr_applicator.application_method.includes("center_") && parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].repeat_distance) > 0){
				let half_length = panel_width/2.0;
                let length_used = 0;

				if(curr_applicator.application_method == "center_odd"){
					front_positions.push(half_length);
				}
				
				var min_start_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_start_distance);
				var min_end_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_end_distance);;

				var repeat_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].repeat_distance);
				
                while((length_used + repeat_distance) < (half_length - min_start_distance)){
                    front_positions.push(half_length - length_used - repeat_distance);
					
                    length_used += repeat_distance;
                }
				
				length_used = 0;
				while((length_used + repeat_distance) < (half_length - min_end_distance)){
					front_positions.push(half_length + length_used + repeat_distance);
					
                    length_used += repeat_distance;
                }
				
			}

			var front_positions_unsorted = JSON.parse(JSON.stringify(front_positions));
			var back_positions_unsorted = JSON.parse(JSON.stringify(back_positions));
			var center_positions_unsorted = JSON.parse(JSON.stringify(center_positions));

			front_positions = front_positions.sort((a,b) => parse_offset_value(a) - parse_offset_value(b));
			back_positions = back_positions.sort((a,b) => parse_offset_value(a) - parse_offset_value(b));
			center_positions = center_positions.sort((a,b) => parse_offset_value(a) - parse_offset_value(b));

			var pt = {
				x: panel_origin.x,
				y: panel_origin.y + (panel_thickness/2)*pixel_per_mm
			}


			if(curr_applicator.application_method.includes("center_") || curr_applicator.application_method == "equal_distance"){
				var min_start_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_start_distance);
				var min_end_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_end_distance);;

				//vertical dotted line double the size of panel_thickness at half length 
				context.setLineDash([5, 5]);

				if(curr_applicator.application_method.includes("center_")){
					context.beginPath();
					context.moveTo(pt.x + pixel_per_mm*panel_width/2, pt.y - (2*panel_thickness)*pixel_per_mm);
					context.lineTo(pt.x + pixel_per_mm*panel_width/2, pt.y + (2*panel_thickness)*pixel_per_mm);
					context.stroke();
				}

				let font_height = 1.2*target_font_size;

				let start_end_helper_half_size = (panel_thickness/2)*pixel_per_mm + dimension_drop_amount - (font_height/2);

				context.beginPath();
				context.moveTo(pt.x + pixel_per_mm*min_start_distance, pt.y);
				context.lineTo(pt.x + pixel_per_mm*min_start_distance, pt.y + start_end_helper_half_size + dimension_drop_amount + font_height);

				context.moveTo(pt.x + pixel_per_mm*min_start_distance, pt.y - start_end_helper_half_size - font_height);
				context.lineTo(pt.x + pixel_per_mm*min_start_distance, pt.y - start_end_helper_half_size - font_height - dimension_drop_amount);

				context.stroke();

				context.beginPath();
				context.moveTo(pt.x + pixel_per_mm*(panel_width - min_end_distance), pt.y);
				context.lineTo(pt.x + pixel_per_mm*(panel_width - min_end_distance), pt.y + start_end_helper_half_size + dimension_drop_amount + font_height);

				context.moveTo(pt.x + pixel_per_mm*(panel_width - min_end_distance), pt.y - start_end_helper_half_size - font_height);
				context.lineTo(pt.x + pixel_per_mm*(panel_width - min_end_distance), pt.y - start_end_helper_half_size - font_height - dimension_drop_amount);
				
				context.stroke();

				// context.textAlign = "center";
				// context.textBaseline = "bottom";
				// context.fillStyle = "#000000";
				// context.fillText("Min gap " + min_start_distance, pt.x + pixel_per_mm*min_start_distance, pt.y - (2*panel_thickness)*pixel_per_mm);
				// context.fillText("Min gap " + min_end_distance, pt.x + pixel_per_mm*(panel_width - min_end_distance), pt.y - (2*panel_thickness)*pixel_per_mm);

				{
					var is_active_start = false;
					if(activeElement && activeElement.object=="min_start_distance"){
						is_active_start = true;
					}

					let p1_start = {x: pt.x,y: pt.y}
					let p2_start = {x: pt.x + pixel_per_mm*min_start_distance,y: pt.y}

					draw_dimension(context, (panel_thickness/2)*pixel_per_mm + dimension_drop_amount, p1_start, p2_start,curr_applicator.placement_styles[placement_style_idx][1].min_start_distance,is_active_start?"#4597f7":"#000000");
				}

				{
					var is_active_end = false;
					if(activeElement && activeElement.object=="min_end_distance"){
						is_active_end = true;
					}

					let p1_end = {x: pt.x + pixel_per_mm*panel_width,y: pt.y}
					let p2_end = {x: pt.x + pixel_per_mm*(panel_width - min_end_distance),y: pt.y}

					draw_dimension(context, (panel_thickness/2)*pixel_per_mm + dimension_drop_amount, p2_end, p1_end,curr_applicator.placement_styles[placement_style_idx][1].min_end_distance,is_active_end?"#4597f7":"#000000");
				}
				
				context.setLineDash([]);

				//draw dimension from center to the first connector to its left
				if(curr_applicator.application_method.includes("center_")){
					var nearest_pos = (panel_width/2.0) - parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].repeat_distance);

					var is_active = false;
					if(activeElement && activeElement.object=="repeat_distance"){
						is_active = true;
					}

					var p1 = {x: pt.x + pixel_per_mm*nearest_pos, y: pt.y};
					var p2 = {x: pt.x + pixel_per_mm*panel_width/2, y: pt.y};
					draw_dimension(context, (panel_thickness/2)*pixel_per_mm + dimension_drop_amount, p1, p2,curr_applicator.placement_styles[placement_style_idx][1].repeat_distance,is_active?"#4597f7":"#000000");
				}
			}



			for(var i=0;i<front_positions.length;i++){
				var is_active = false;

				var unsorted_index = front_positions_unsorted.indexOf(front_positions[i]);
				if(activeElement && activeElement.object=="offset" && activeElement.from == "front" && activeElement.idx == unsorted_index){
					is_active = true;
				}

				if(curr_applicator.application_method == "equal_distance"){
					if(i != front_positions.length-1 && front_positions.length>2){
						var p1 = {x:pt.x + pixel_per_mm*parse_offset_value(front_positions[i]),y:pt.y};
						var p2 = {x:pt.x + pixel_per_mm*parse_offset_value(front_positions[i+1]),y:pt.y};
						draw_dimension(context, (panel_thickness/2)*pixel_per_mm + dimension_drop_amount, p1, p2,"EQ",is_active?"#4597f7":"#000000");
					}else if(i == 0 && front_positions.length==1){
						var min_start_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_start_distance);
						var min_end_distance = parse_offset_value(curr_applicator.placement_styles[placement_style_idx][1].min_end_distance);;

						var p1 = {x:pt.x + pixel_per_mm*min_start_distance,y:pt.y};
						var p2 = {x:pt.x + pixel_per_mm*(panel_width - min_end_distance),y:pt.y};
						var p = {x:pt.x + pixel_per_mm*parse_offset_value(front_positions[i]),y:pt.y};
						draw_dimension(context, (panel_thickness/2)*pixel_per_mm + dimension_drop_amount, p1, p,"EQ",is_active?"#4597f7":"#000000");
						draw_dimension(context, (panel_thickness/2)*pixel_per_mm + dimension_drop_amount, p, p2,"EQ",is_active?"#4597f7":"#000000");
					}
				}else if(curr_applicator.application_method == "free"){
					var p1 = {x:pt.x,y:pt.y};
					var p2 = {x:pt.x + pixel_per_mm*parse_offset_value(front_positions[i]),y:pt.y};
					draw_dimension(context, (panel_thickness/2)*pixel_per_mm + (i+1)*dimension_drop_amount, p1, p2,front_positions[i],is_active?"#4597f7":"#000000");
				}

				var opacity = 1;
				if(curr_applicator.application_method.includes("center_")){
					let half_length = panel_width/2.0;

					//opacity is 1 at the center and decays upto 0.5 as you go away from the center
					opacity = 1 - Math.abs(parse_offset_value(front_positions[i]) - half_length)/(half_length);
				}

				drawConnectorCursor(context, pt.x + pixel_per_mm*parse_offset_value(front_positions[i]), pt.y, (panel_thickness/4)*pixel_per_mm,"#000000",1,opacity);
			}
			
			for(var i=0;i<back_positions.length;i++){
				var is_active = false;
				
				var unsorted_index = back_positions.indexOf(back_positions[i]);

				if(activeElement && activeElement.object=="offset" && activeElement.from == "back" && activeElement.idx == unsorted_index){
					is_active = true;
				}

				if(curr_applicator.application_method == "free"){
					var p1 = {x:pt.x + pixel_per_mm*panel_width,y:pt.y};
					var p2 = {x:pt.x + pixel_per_mm*panel_width - pixel_per_mm*parse_offset_value(back_positions[i]),y:pt.y};
					draw_dimension(context, (panel_thickness/2)*pixel_per_mm + (i+1)*dimension_drop_amount, p1,p2,back_positions[i],is_active?"#4597f7":"#000000");
				}
				
				drawConnectorCursor(context, pt.x + pixel_per_mm*panel_width - pixel_per_mm*parse_offset_value(back_positions[i]), pt.y, (panel_thickness/4)*pixel_per_mm,"#000000",1);
			}

			var center_left_connectors_count = center_positions.filter(o => parse_offset_value(o) < 0).length;
			var center_right_connectors_count = 0;
			for(var i=0;i<center_positions.length;i++){
				var is_active = false;

				var unsorted_index = center_positions_unsorted.indexOf(center_positions[i]);
				if(activeElement && activeElement.object=="offset" && activeElement.from == "center" && activeElement.idx == unsorted_index){
					is_active = true;
				}

				if(curr_applicator.application_method == "free"){
					var p1 = {x:pt.x + (pixel_per_mm*panel_width/2),y:pt.y};
					var p2 = {x:pt.x + (pixel_per_mm*panel_width/2) + pixel_per_mm*parse_offset_value(center_positions[i]),y:pt.y};
					var current_count;
					if(p1.x < p2.x){
						center_right_connectors_count += 1;
						current_count = center_right_connectors_count;
					}else{
						var temp = p1;
						p1 = p2;
						p2 = temp;
						center_left_connectors_count -= 1;
						current_count = center_left_connectors_count+1;
					}
					draw_dimension(context, (panel_thickness/2)*pixel_per_mm + current_count*dimension_drop_amount, p1,p2,center_positions[i],is_active?"#4597f7":"#000000");
				}
				
				drawConnectorCursor(context, pt.x + (pixel_per_mm*panel_width/2) + pixel_per_mm*parse_offset_value(center_positions[i]), pt.y, (panel_thickness/4)*pixel_per_mm,"#000000",1);
			}

			context.save();
			context.textAlign = "right";
			context.textBaseline = "bottom";
			context.fillStyle = "#000000";
			context.fillText("Start", pt.x - 5, pt.y);
			context.textAlign = "left";
			context.fillText("End", pt.x + 5 + pixel_per_mm*panel_width, pt.y);
			context.restore();

			//need a dark grey circle with line to the right of it under the Start text
			context.save();

			var radius = target_font_size*0.3;
			context.fillStyle = "#757678";
			context.strokeStyle = "#757678";
			context.lineWidth = target_font_size*0.15;

			context.beginPath();
			context.arc(pt.x - target_font_size*2, pt.y + 2*radius, radius, 0, 2 * Math.PI, false);
			context.fill();
			context.stroke();
			context.beginPath();
			context.moveTo(pt.x - target_font_size*2 + radius, pt.y + 2*radius);
			context.lineTo(pt.x - target_font_size*0.5, pt.y + 2*radius);
			context.stroke();

			//now a rightward arrow at the end side. use filled triangle for arrowhead
			context.beginPath();
			context.moveTo(pt.x + pixel_per_mm*panel_width + target_font_size*2, pt.y + 2*radius);
			context.lineTo(pt.x + pixel_per_mm*panel_width + target_font_size*0.5, pt.y + 2*radius);
			context.stroke();
			context.beginPath();
			context.moveTo(pt.x + pixel_per_mm*panel_width + target_font_size*2, pt.y + 2*radius);
			context.lineTo(pt.x + pixel_per_mm*panel_width + target_font_size*1.4, pt.y + 2*radius - target_font_size*0.4);
			context.lineTo(pt.x + pixel_per_mm*panel_width + target_font_size*1.4, pt.y + 2*radius + target_font_size*0.4);
			context.fill();

			context.restore();

			// drawCircledText(context, "S", pt.x, pt.y, (panel_thickness/3)*pixel_per_mm, "#000000");
			// drawCircledText(context, "E", pt.x + (pixel_per_mm*panel_width), pt.y, (panel_thickness/3)*pixel_per_mm, "#000000");
		}else if(type == "single_panel_connector" || type == "cabinet_connector"){
			var back_left_positions = get_filtered_offsets("back","left");
			var back_right_positions = get_filtered_offsets("back","right");
			var front_right_positions = get_filtered_offsets("front","right");
			var front_left_positions = get_filtered_offsets("front","left");

			for(var i=0;i<back_left_positions.length;i++){
				var is_dotted = false;
				if(type == "single_panel_connector"){
					var panel_face = curr_applicator.placement_styles[placement_style_idx][1].offsets[get_filtered_indices("back","left")[i]].panel_face;
					if(panel_face == "bottom"){
						is_dotted = true;
					}
				}
				var is_active_hori = false; var is_active_vert = false;
				if(activeElement && activeElement.object=="offset" && activeElement.from == "back"  && activeElement.from1 == "left" && activeElement.idx == i){
					if(activeElement.curr_from == "back"){
						is_active_vert = true;
					}else if(activeElement.curr_from == "left"){
						is_active_hori = true;
					}
				}

				var p = {x: panel_origin.x + pixel_per_mm*parse_offset_value(back_left_positions[i].x), y: panel_origin.y + pixel_per_mm*parse_offset_value(back_left_positions[i].y)};
				draw_dimension(context, 0, p , {x: panel_origin.x, y: p.y},back_left_positions[i].x,is_active_hori?"#4597f7":"#000000",is_dotted);
				draw_dimension(context, 0, p , {x: p.x, y: panel_origin.y},back_left_positions[i].y,is_active_vert?"#4597f7":"#000000",is_dotted);

				drawConnectorCursor(
					context,
					p.x,
					p.y,
					(panel_thickness/3)*pixel_per_mm,"#000000",1
				);

			}

			for(var i=0;i<back_right_positions.length;i++){
				var is_dotted = false;
				if(type == "single_panel_connector"){
					var panel_face = curr_applicator.placement_styles[placement_style_idx][1].offsets[get_filtered_indices("back","right")[i]].panel_face;
					if(panel_face == "bottom"){
						is_dotted = true;
					}
				}

				var is_active_hori = false; var is_active_vert = false;
				if(activeElement && activeElement.object=="offset" && activeElement.from == "back"  && activeElement.from1 == "right" && activeElement.idx == i){
					if(activeElement.curr_from == "back"){
						is_active_vert = true;
					}else if(activeElement.curr_from == "right"){
						is_active_hori = true;
					}
				}

				var p = {x: panel_origin.x + pixel_per_mm*(panel_width - parse_offset_value(back_right_positions[i].x)),y: panel_origin.y + pixel_per_mm*parse_offset_value(back_right_positions[i].y)};
				draw_dimension(context, 0, p , {x: panel_origin.x + pixel_per_mm*panel_width, y: p.y},back_right_positions[i].x,is_active_hori?"#4597f7":"#000000",is_dotted);
				draw_dimension(context, 0, p , {x: p.x, y: panel_origin.y},back_right_positions[i].y,is_active_vert?"#4597f7":"#000000",is_dotted);
				
				drawConnectorCursor(
					context,
					p.x,
					p.y,
					(panel_thickness/3)*pixel_per_mm,"#000000",1
				);

			}

			for(var i=0;i<front_right_positions.length;i++){
				var is_dotted = false;
				if(type == "single_panel_connector"){
					var panel_face = curr_applicator.placement_styles[placement_style_idx][1].offsets[get_filtered_indices("front","right")[i]].panel_face;
					if(panel_face == "bottom"){
						is_dotted = true;
					}
				}

				var is_active_hori = false; var is_active_vert = false;
				if(activeElement && activeElement.object=="offset" && activeElement.from == "front"  && activeElement.from1 == "right" && activeElement.idx == i){
					if(activeElement.curr_from == "front"){
						is_active_vert = true;
					}else if(activeElement.curr_from == "right"){
						is_active_hori = true;
					}
				}

				var p = {x: panel_origin.x + pixel_per_mm*(panel_width - parse_offset_value(front_right_positions[i].x)), y: panel_origin.y + pixel_per_mm*(panel_depth - parse_offset_value(front_right_positions[i].y))}
				draw_dimension(context, 0, p , {x: panel_origin.x + pixel_per_mm*panel_width, y: p.y},front_right_positions[i].x,is_active_hori?"#4597f7":"#000000",is_dotted);
				draw_dimension(context, 0, p , {x: p.x, y: panel_origin.y + pixel_per_mm*panel_depth},front_right_positions[i].y,is_active_vert?"#4597f7":"#000000",is_dotted);
				
				drawConnectorCursor(
					context,
					p.x,
					p.y,
					(panel_thickness/3)*pixel_per_mm,"#000000",1
				);

			}

			for(var i=0;i<front_left_positions.length;i++){
				var is_dotted = false;
				if(type == "single_panel_connector"){
					var panel_face = curr_applicator.placement_styles[placement_style_idx][1].offsets[get_filtered_indices("front","left")[i]].panel_face;
					if(panel_face == "bottom"){
						is_dotted = true;
					}
				}

				var is_active_hori = false; var is_active_vert = false;
				if(activeElement && activeElement.object=="offset" && activeElement.from == "front"  && activeElement.from1 == "left" && activeElement.idx == i){
					if(activeElement.curr_from == "front"){
						is_active_vert = true;
					}else if(activeElement.curr_from == "left"){
						is_active_hori = true;
					}
				}

				var p = {x: panel_origin.x + pixel_per_mm*parse_offset_value(front_left_positions[i].x), y: panel_origin.y + pixel_per_mm*(panel_depth - parse_offset_value(front_left_positions[i].y))}
				draw_dimension(context, 0, p , {x: panel_origin.x, y: p.y},front_left_positions[i].x,is_active_hori?"#4597f7":"#000000",is_dotted);
				draw_dimension(context, 0, p , {x: p.x, y: panel_origin.y + pixel_per_mm*panel_depth},front_left_positions[i].y,is_active_vert?"#4597f7":"#000000",is_dotted);
				
				drawConnectorCursor(
					context,
					p.x,
					p.y,
					(panel_thickness/3)*pixel_per_mm,"#000000",1
				);

			}
		}

		if(with_controller && (curr_applicator.application_method == "free" || curr_applicator.application_method == "equal_distance")){
			var connector_buttons = get_connector_buttons();
			var active_name = activeElement&&activeElement.object?activeElement.object:""
			
			for(var i=0;i<connector_buttons.length;i++){
				var curr_button = connector_buttons[i];
				drawCircledText(context, curr_button.display, curr_button.x, curr_button.y, (active_name==curr_button.key?1.5:1)*curr_button.radius, curr_button.color);
				if(curr_button.text){
					context.font = (target_font_size).toFixed(0) + "px sans-serif";
					context.textBaseline = "middle";
					context.fillStyle = curr_button.color;
					if(curr_button.text_placement == "right"){
						context.textAlign = "left";
						context.fillText(curr_button.text, curr_button.x + (2*curr_button.radius), curr_button.y);
					}else if(curr_button.text_placement == "left"){
						context.textAlign = "right";
						context.fillText(curr_button.text, curr_button.x - (2*curr_button.radius), curr_button.y);
					}
				}
			}
		}
	}

    return (
		<React.Fragment>
			<canvas onMouseMove={mousemove} onMouseUp={mouseup} ref={canvasRef} width={canvas_width} height={canvas_height} style={{width:canvas_width,height:canvas_height}}></canvas>
			{activeTextBox?(
				<React.Fragment>
					{/* {(activeTextBox.object=="repeat_distance" || activeTextBox.object=="min_start_distance" || activeTextBox.object=="min_end_distance")?(
						<input type="text" defaultValue={activeTextBox.val} onKeyUp={(e) => { if(e.key == "Enter"){active_text_keyup(e.target.value)} }} style={{backgroundColor:activeTextBox.is_entered_text_invalid?"#e6aeb3":"white", textAlign:"center",fontSize:12,width:80,height:27,position:"fixed",left:activeTextBox.x - 40,top:activeTextBox.y - 13}} />
					):( */}
						<ExpressionInput 
							default_value={activeTextBox.val}
							expression_available_parameters={{panel_width, panel_depth, panel_thickness, total_length: panel_width}}
							on_update={active_text_keyup}
							style_override={{textAlign:"center",fontSize:12,position:"fixed",left:activeTextBox.x - 40,top:activeTextBox.y - 13}}
						/>
					{/* )} */}
				</React.Fragment>
			):''}
		</React.Fragment>
    )
}


export default ConnectorCanvasComponent;