import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { CoreControlledTable } from '.';
import Icons from '../../shared_components/ui_component_library/Icons';
import { IModalBody, IModalFooter, IModalHeader } from '../../shared_components/ui_component_library/IModal';
import ISearch from '../ISearch';
import { Button, Input, Modal } from 'reactstrap';
import { isNonEmptyArray } from '../../utils/array';
import Select from 'react-select';
import INavBar, { UncontrolledINavbar } from '../../shared_components/ui_component_library/INavBar';
import { Checkbox, Radio, Dropdown as AntDropdown, Steps, DatePicker, Menu, Tooltip } from 'antd';
import { handleError } from '../../utils/errors';
import { ITD, ITH, ITable, ITableBody, ITableHeader, ITableRow } from '../../shared_components/ui_component_library/ITable';
import includes_search_string from '../../utils/includes_search_string';
import debounce from 'lodash.debounce';
import ApplicatorCard from '../../shared_components/cam_components/ApplicatorCard';
import ModifyConnector from '../../shared_components/cam_components/ModifyConnector';
import IButton from '../../shared_components/ui_component_library/IButton';
import ITriggerModal from '../../shared_components/ui_component_library/ITriggerModal';
import { get_sku_details_core_format } from '../../shared_components/utils/sku';
import { useAlert } from 'react-alert';
import Masonry from 'react-masonry-css'

// Add this CSS in your stylesheet
const masonryStyles = {
	display: "flex",
	marginLeft: "-16px", // Compensate for padding
	width: "auto"
};
  

const _deref = (a) => (JSON.parse(JSON.stringify(a)));

export const canUserPublish = () => {
	try{

		let user  = window.Module.get_active_user();
		let roles = user.get_roles();
		// Allowed roles to publish - catalog_manager, production_designer
		if(roles.includes("catalog_manager") || roles.includes("production_designer")){
			return true;
		}
	}catch(err){
		console.error(`Error in canUserPublish `, err)
		handleError(err);
	}
	return false;
}

const modularUnitTooltip = (modular_unit) => {
	let sku_details = modular_unit && modular_unit.sku_id && JSON.parse(window.Module.get_sku_details_from_core(modular_unit.sku_id)) || {};
	return (
		<div className='flex_column inf-gap-2'>
			<div className='flex_property ing-gap-2'><div style={{width: '33%', fontWeight: 500}}>SKU Name :</div> {modular_unit.sku_name} </div>
			<div className='flex_property ing-gap-2'><div style={{width: '33%', fontWeight: 500}}>Serial No :</div> {sku_details.sku_serial_number}</div>
			<div className='flex_property ing-gap-2'><div style={{width: '33%', fontWeight: 500}}>Group :</div> {sku_details.group_name}</div>
			<div className='flex_property ing-gap-2'><div style={{width: '33%', fontWeight: 500}}>SubCategory :</div> {sku_details.sub_category_name}</div>
			<div className='flex_property ing-gap-2'><div style={{width: '33%', fontWeight: 500}}>Category :</div> {sku_details.category_name}</div>
		</div>
	)
}

const ConnectorSetModalFilters = ({ applied_filters, set_applied_filters, modal_json }) => {
	const [panel_1_type_filter, set_panel_1_type_filter] = useState();
	const [panel_2_type_filter, set_panel_2_type_filter] = useState();
	const [scenario_type_filter, set_scenario_type_filter] = useState();
	const [hw_source_filter, set_hw_source_filter] = useState();
	const [has_collisions_filter, set_has_collisions_filter] = useState();
	const [modular_units_filter, set_modular_units_filter] = useState();
	const [connector_filter, set_connector_filter] = useState();
	const [connector_status_filter, set_connector_status_filter] = useState();
	const [filter_key, set_filter_key] = useState("default");
	const changeTriggered = useRef(false)

	const scenario_type_options = [
		{"name": "Dowel", value: "dowel"},
		{"name": "Hinge", value: "hinge"},
		{"name": "Single Panel", value: "single_panel"},
		{"name": "Cabinet to Cabinet", value: "cabinet_connector"}
	]

	const hw_source_options = [
		{"name": "Rule", value: "rule"},
		{"name": "Manual", value: "manual"}
	]

	const collisions_filter_options = [
		{"name": "True", value: "true"},
		{"name": "False", value: "false"}
	]

	const onChangeFilter = (type, value) => {
		changeTriggered.current = true
		if (type === "panel_1_type") {
			set_panel_1_type_filter(value)
		}

		if (type === "panel_2_type") {
			set_panel_2_type_filter(value)
		}

		if (type === "scenario_type") {
			set_scenario_type_filter(value)
		}

		if (type === "hw_source") {
			set_hw_source_filter(value)
		}

		if (type === "has_collisions") {
			set_has_collisions_filter(value)
		}

		if (type === "modular_units") {
			set_modular_units_filter(value)
		}

		if (type === "connector") {
			set_connector_filter(value)
		}

		if (type === "connector_status") {
			set_connector_status_filter(value)
		}
	}

	const applyFilter = () => {
		changeTriggered.current = false
		set_applied_filters({
			"panel_1_type": panel_1_type_filter,
			"panel_2_type": panel_2_type_filter,
			"modular_units": modular_units_filter,
			"scenario_type": scenario_type_filter,
			"connector": connector_filter,
			"connector_status": connector_status_filter,
			"hw_source": hw_source_filter,
			"has_collisions": has_collisions_filter
		})
	}

	const clearFilter = () => {
		changeTriggered.current = false
		set_applied_filters({})
	}

	useEffect(() => {
		set_panel_1_type_filter(applied_filters.panel_1_type)
		set_panel_2_type_filter(applied_filters.panel_2_type)
		set_modular_units_filter(applied_filters.modular_units)
		set_scenario_type_filter(applied_filters.scenario_type)
		set_hw_source_filter(applied_filters.hw_source)
		set_has_collisions_filter(applied_filters.has_collisions)
		set_connector_filter(applied_filters.connector)
		set_connector_status_filter(applied_filters.connector_status)
		set_filter_key(Math.random())
	}, [applied_filters]);

	const getConnectorOptions = () => {
		let _connector_options = _deref(modal_json && modal_json.available_connectors || []);
		_connector_options.sort((a, b) => a.name.localeCompare(b.name));
		_connector_options.unshift({ id: "", name: "No Connector" })
		return _connector_options
	} 

	return (
		<div className='connector_set_modal_filters' style={{overflow:"scroll"}}>
			<div className='connector_set_modal_filters_header'>
				Filters
			</div>
			<React.Fragment key={filter_key}>

				<div className='connector_set_modal_filters_container'>
					<div>Scenarios (choose panel type)</div>
					<Select
						placeholder="Select a panel"
						onChange={(value) => onChangeFilter("panel_1_type", value)}
						options={modal_json && modal_json.panel_type_options || []}
						value={panel_1_type_filter}
						getOptionValue={(option) => option.value}
						getOptionLabel={(option) => option.name}
						isClearable
					/>
					<Select
						placeholder="between a panel"
						onChange={(value) => onChangeFilter("panel_2_type", value)}
						options={modal_json && modal_json.panel_type_options || []}
						value={panel_2_type_filter}
						getOptionValue={(option) => option.value}
						getOptionLabel={(option) => option.name}
						isClearable
					/>
				</div>

				<div className='connector_set_modal_filters_container'>
					<div>Modular units</div>
					<Select
						isMulti
						placeholder="Select units"
						onChange={(value) => onChangeFilter("modular_units", value)}
						options={modal_json && modal_json.modular_units_json || []}
						value={modular_units_filter}
						getOptionValue={(option) => option.id}
						getOptionLabel={(option) => <Tooltip trigger={['hover']} placement="bottomRight" overlayStyle={{ width: '400px', maxWidth: 'unset' }} overlayInnerStyle={{ maxHeight: '500px', overflow: 'auto' }} title={modularUnitTooltip(option)}><div className='flex_property inf-gap-2'>{option.unique_label} <div className='flex-1-mw lines1_elipsis inf_cam_flow_modular_units_filter_info'>{option.sku_name}</div></div></Tooltip>}
						closeMenuOnSelect={false}
						classNames={{control: (props) => {
							return 'inf_cam_flow_modular_units_filter_control'
						}}}
						styles={{
							multiValue: (styles) => {
								return {
									...styles,
									backgroundColor: '#0078ff33',
								};
							},
							multiValueLabel: (styles) => ({
								...styles,
								color: '#0078ff',
							})
						}}
					/>
				</div>

				{/* <div className='connector_set_modal_filters_container'>
				<div>Modular unit type</div>
				<Select
					isMulti
					placeholder="Select unit type"
					// onChange={handleOnChange}
					// options={users}
					// value={selected_users}
					/>
			</div> */}

				<div className='connector_set_modal_filters_container'>
					<div>Scenario type</div>
					<Select
						placeholder="Select a scenario type"
						onChange={(value) => onChangeFilter("scenario_type", value)}
						options={scenario_type_options || []}
						value={scenario_type_filter}
						getOptionValue={(option) => option.value}
						getOptionLabel={(option) => option.name}
						isClearable
					/>
				</div>

				<div className='connector_set_modal_filters_container'>
					<div>Connector</div>
					<Select
						isMulti
						placeholder="Choose connector"
						onChange={(value) => onChangeFilter("connector", value)}
						options={getConnectorOptions()}
						value={connector_filter}
						getOptionValue={(option) => option.id}
						getOptionLabel={(option) => option.name}
						closeMenuOnSelect={false}
						styles={{
							multiValue: (styles) => {
								return {
									...styles,
									backgroundColor: '#0078ff33',
								};
							},
							multiValueLabel: (styles) => ({
								...styles,
								color: '#0078ff',
							})
						}}
					/>
					<Select
						placeholder="Choose state"
						onChange={(value) => onChangeFilter("connector_status", value)}
						options={[{ id: "applied", name: "Applied" }, { id: "not_applied", name: "Not Applied" }]}
						value={connector_status_filter}
						getOptionValue={(option) => option.id}
						getOptionLabel={(option) => option.name}
					/>
				</div>

				<div className='connector_set_modal_filters_container'>
					<div>Collisions</div>
					<Select
						placeholder="Scenarios with collisions"
						onChange={(value) => onChangeFilter("has_collisions", value)}
						options={collisions_filter_options || []}
						value={has_collisions_filter}
						getOptionValue={(option) => option.value}
						getOptionLabel={(option) => option.name}
						isClearable
					/>
				</div>

				<div className='connector_set_modal_filters_container'>
					<div>Scenario Rule applicability</div>
					<Select
						placeholder="Select rule applicability"
						onChange={(value) => onChangeFilter("hw_source", value)}
						options={hw_source_options || []}
						value={hw_source_filter}
						getOptionValue={(option) => option.value}
						getOptionLabel={(option) => option.name}
						isClearable
					/>
				</div>


			</React.Fragment>

			<div className='connector_set_modal_filters_footer'>
				<Button className='red_button_default' onClick={clearFilter}>Clear All</Button>
				<Button className='primary_button_default' disabled={!changeTriggered.current} onClick={applyFilter}>Apply Filter</Button>
			</div>
		</div>
	)
}

const ConnectorSetCardWrapper = ({ children, sku_details, connector, applied_scenarios_length, isConnectorCardVisible }) => {
	return (
		<div className='connector_set_modal_connector_card inf-border-dark' style={{ gridRow: connector.applicator_type === "single_panel_connector" ? 'span 2' : '', display: isConnectorCardVisible && !isConnectorCardVisible(connector, sku_details) ? 'none' : '' }}>
			{children}
			<div className='connector_set_modal_connector_card_footer'>
				<div>
					Applied scenarios: <b>{applied_scenarios_length}</b>
				</div>
				{/* <div>
					Not Applied: 
				</div> */}
			</div>
		</div>
	)
}

const ConnectorSetCard = ({ connector, onClickEditConnector, isConnectorCardVisible, modal_json, ...props }) => {
	let applied_scenarios_length = connector && connector.scenarios_being_used_in && connector.scenarios_being_used_in.length || 0
	const isPushblishDisabled = (connector) => {
        return !(connector && connector.is_fe) || !canUserPublish()
    }
	const publish_on_click = async () => {
        let resp = await window.Promisify(window.Module.publish_cam_applicator(connector.id));
		let final_connector_scenario_data = {};
		final_connector_scenario_data.connectors = _deref(modal_json.available_connectors);
		let edited_connector = final_connector_scenario_data.connectors && final_connector_scenario_data.connectors.find(x => x.id === connector.id);
		edited_connector.is_fe = false;
		// let edited_connector_id = chosen_connector && chosen_connector.id;
		final_connector_scenario_data.scenarios = modal_json.available_scenarios;
		await updateConnectorSetDetails(JSON.stringify(final_connector_scenario_data))
        window.handle_ui_response(resp);
    }
	return (
		<ApplicatorCard o={connector} edit_selected_applicator={onClickEditConnector} style={{ flex: 1 }} ApplicatorCardWrapper={ConnectorSetCardWrapper} ApplicatorCardWrapperProps={{isConnectorCardVisible, connector, applied_scenarios_length}} dropdown_buttons={[
			// {
			//     display_name: "Change connector",
			//     customOnClick: change_on_click
			// },
			// {
			//     display_name: "Remove connector",
			//     customOnClick: remove_on_click,
			//     color: '#FF5247'
			// }
			{
				display_name: "Publish",
				disabled: isPushblishDisabled(connector),
				customOnClick: publish_on_click,
				tooltip: isPushblishDisabled(connector) ? !canUserPublish() ? "You need catalogue manager or production designer access to publish" : "Only connectors created within design can be published" : ""
			}
		]} {...props}/>
	)
}

const ConnectorSetSelectionCard = ({ connector, checked, isMulti, onSelect }) => {
	return (
		<div className='connector_set_selection_card' onClick={onSelect}>
			{isMulti ? <Checkbox checked={checked} style={{ marginTop: '12px' }} /> : <Radio checked={checked} style={{ marginTop: '12px' }} />}
			<ApplicatorCard o={connector} style={{ flex: 1, minWidth: '0px' }} />
		</div>
	)
}

export const ConnectorSetModal = ({ modal_type, modal_json, handle_close, update_view, update_modal_json, handle_ui_response, set_page_loader }) => {
	// Define the state variables here, use us for template
	const [active_tab, set_active_tab] = useState();
	const [applied_filters, set_applied_filters] = useState({});
	const [mode, set_mode] = useState("default");
	const [active_scenarios, set_active_scenarios] = useState([]);
	const [table_data, set_table_data] = useState({});
	const [pre_selected_connector, set_pre_selected_connector] = useState();

	const [search_string_scenarios, set_search_string_scenarios] = useState('');
	const [search_string_connectors, set_search_string_connectors] = useState('');
	// const deferred_search_string_scenarios = useDeferredValue(search_string_scenarios);

	const [debounced_search_string_scenarios, set_debounced_search_string_scenarios] = useState(search_string_scenarios);
	const [debounced_search_string_connectors, set_debounced_search_string_connectors] = useState(search_string_connectors);

	const filterApplied = useRef(false);

	// Define functions here, use fin or afin for template

	const onclick_handle_close = () => {
		handle_close()
	}

	const onClickBulkAction = ({ key }) => {
		try {
			console.log("key - bulk action ", key, table_data)
			let active_scenarios_map = {};
			if (table_data && isNonEmptyArray(table_data.units)) {
				for (let i in table_data.units) {
					let unit = table_data.units[i];
					if (unit.checked) {
						active_scenarios_map[unit.id] = true
					}
				}
			}
			set_mode(key);
			let selected_scenarios = modal_json && isNonEmptyArray(modal_json.available_scenarios) && modal_json.available_scenarios.filter(x => active_scenarios_map[x.id]) || [];
			console.log("selected scenario - bulk action ", selected_scenarios);
			set_active_scenarios(selected_scenarios);

		} catch (err) {
			console.error(`Error in onClickBulkAction `, err)
			handleError(err);
		}
	}

	const onClickAction = (key, scenario) => {
		try {
			console.log("key - bulk action ", key, scenario)
			set_active_scenarios([scenario]);
			set_mode(key);
		} catch (err) {
			console.error(`Error in onClickBulkAction `, err)
			handleError(err);
		}
	}

	const get_readable_scenario_type = (scenario_type) => {
		if(scenario_type.includes("dowel")){
			return "Dowel"
		}else if(scenario_type.includes("hinge")){
			return "Hinge"
		}else if(scenario_type.includes("single_panel")){
			return "Single Panel"
		}else if(scenario_type.includes("cabinet_connector")){
			return "Cabinet to Cabinet"
		}
	}

	const get_collision_tooltip = (filter_collisions) => {
		let tooltip_message = "";
		let collistion_types = {};
		filter_collisions.map(o => {
			if(!collistion_types[o.name]){
				collistion_types[o.name] = 0;
			}
			collistion_types[o.name] += 1;
		})

		Object.keys(collistion_types).map(key => {
			if(tooltip_message != ""){
				tooltip_message += ", ";
			}
			tooltip_message += (key + " (" + collistion_types[key] + ")");
		})

		return tooltip_message;
	}


	const { display_scenarios, display_connectors, display_scenarios_array, scenarios_with_no_connector } = useMemo(() => {
		let filtered_scenarios_array = [];
		let filtered_scenarios = {
			"col": [
				{
					"id": "checked",
					"name": "",
					"type": "checkbox"
				},
				{
					"id": "scenario_type",
					"name": "Scenario type",
					"type": "text",
					"sortable": true
				},
				{
					"id": "modular_unit",
					"name": "Modular unit",
					"type": "custom",
					"sortable": true,
					"getDataLabel": (unit) => {
						let modular_unit = unit.modular_unit;
						let s = modular_unit ? `${modular_unit.unique_label} (${modular_unit.sku_name})` : '-'
						if(s.length <= 13){
							return s;
						}
						return s.substring(0, 10) + '...'
					},
					"getDataTooltip": (unit) => {
						let modular_unit = unit.modular_unit;
						return modularUnitTooltip(modular_unit)
					},
					"tooltipProps": {overlayStyle: {width: '400px', maxWidth: 'unset'}, overlayInnerStyle: {maxHeight: '500px', overflow: 'auto'}}
				},
				{
					"id": "scenario_length",
					"name": "Scenario Length (mm)",
					"type": "text",
					"sortable": true
				},
				{
					"id": "panel_1_type",
					"name": "Panel 1 Type",
					"type": "text",
					"sortable": true
				},
				{
					"id": "panel_1_face",
					"name": "Panel 1 Face",
					"type": "text",
					"sortable": true
				},
				{
					"id": "panel_2_type",
					"name": "Panel 2 Type",
					"type": "text",
					"sortable": true
				},
				{
					"id": "panel_2_face",
					"name": "Panel 2 Face",
					"type": "text",
					"sortable": true
				},
				{
					"id": "connectors",
					"name": "Connectors",
					"type": "text",
					"sortable": true
				},
				// {
				// 	"id": "action",
				// 	"name": "Action",
				// 	"type": "antd_dropdown"
				// },
				{
					"id": "info",
					"name": "Info",
					"type": "node"
				},
			],
		}

		let scenarios_to_collisions_map = {};
		if (modal_json && isNonEmptyArray(modal_json.collisions)) {
			for (let i in modal_json.collisions) {
				let collision = modal_json.collisions[i];
				if (!scenarios_to_collisions_map[collision.scenario]) {
					scenarios_to_collisions_map[collision.scenario] = [];
				}
				scenarios_to_collisions_map[collision.scenario].push(collision);
			}
		}

		let scenarios = [];
		let is_scenario_visible = {}
		if (modal_json && isNonEmptyArray(modal_json.available_scenarios)) {
			let modular_elements_map = {};
			modal_json.modular_units_json.map(x => {
				modular_elements_map[x.id] = x;
			})

			let panel_types_map = {};
			modal_json.panel_type_options.map(x => {
				panel_types_map[x.value] = x;
			})
			for (let i in modal_json.available_scenarios) {
				let scenario = modal_json.available_scenarios[i];
				let modular_unit = modular_elements_map[scenario.modular_element_id];
				let panel_1_type = {}, panel_2_type = {};
				let attached_panels = (Array.isArray(scenario.attached_panels) && scenario.attached_panels) || [];
				if (attached_panels.length >= 1) {
					let panel = attached_panels[0];
					panel_1_type = { ...panel, name: panel_types_map[panel.panel_type] ? panel_types_map[panel.panel_type].name : '' };
				}
				if (attached_panels.length >= 2) {
					let panel = attached_panels[1];
					panel_2_type = { ...panel, name: panel_types_map[panel.panel_type] ? panel_types_map[panel.panel_type].name : '' };
				}
				let connectors = scenario.cam_applicator_ids && Array.isArray(scenario.cam_applicator_ids) ? scenario.cam_applicator_ids : [];

				if (applied_filters) {
					let panel_1_type_filter = applied_filters.panel_1_type;
					let panel_2_type_filter = applied_filters.panel_2_type;
					let modular_units_filter = applied_filters.modular_units;
					let scenario_type_filter = applied_filters.scenario_type;
					let connector_filter = applied_filters.connector;
					let connector_status_filter = applied_filters.connector_status;
					let hw_source_filter = applied_filters.hw_source;
					let has_collisions_filter = applied_filters.has_collisions;

					if(panel_1_type_filter && panel_1_type_filter.value || panel_2_type_filter && panel_2_type_filter.value || isNonEmptyArray(modular_units_filter) || scenario_type_filter && scenario_type_filter.value || isNonEmptyArray(connector_filter) || connector_status_filter && connector_status_filter.id){
						filterApplied.current = true;
					}else{
						filterApplied.current = false;
					}

					if (panel_1_type_filter && panel_1_type_filter.value && panel_2_type_filter && panel_2_type_filter.value) {
						if (!(((isNonEmptyArray(panel_1_type.all_panel_types) && panel_1_type.all_panel_types.includes(panel_1_type_filter.value)) && (isNonEmptyArray(panel_2_type.all_panel_types) && panel_2_type.all_panel_types.includes(panel_2_type_filter.value))) || ((isNonEmptyArray(panel_1_type.all_panel_types) && panel_1_type.all_panel_types.includes(panel_2_type_filter.value)) && (isNonEmptyArray(panel_2_type.all_panel_types) && panel_2_type.all_panel_types.includes(panel_1_type_filter.value))))) {
							continue;
						}
					} else {

						if (panel_1_type_filter && panel_1_type_filter.value) {
							if (!((isNonEmptyArray(panel_1_type.all_panel_types) && panel_1_type.all_panel_types.includes(panel_1_type_filter.value)) || (isNonEmptyArray(panel_2_type.all_panel_types) && panel_2_type.all_panel_types.includes(panel_1_type_filter.value)))) {
								continue;
							}
						}

						if (panel_2_type_filter && panel_2_type_filter.value) {
							if (!((isNonEmptyArray(panel_2_type.all_panel_types) && panel_2_type.all_panel_types.includes(panel_2_type_filter.value)) || (isNonEmptyArray(panel_1_type.all_panel_types) && panel_1_type.all_panel_types.includes(panel_2_type_filter.value)))) {
								continue;
							}
						}
					}

					if (modular_units_filter && isNonEmptyArray(modular_units_filter)) {
						if (!modular_units_filter.find(x => x.id === modular_unit.id)) {
							continue;
						}
					}

					if (scenario_type_filter && scenario_type_filter.value) {
						if(!scenario.connection_type.includes(scenario_type_filter.value)){
							continue;
						}
					}

					if(hw_source_filter && hw_source_filter.value){
						if(hw_source_filter.value === "rule" && !scenario.connection_type.includes("default")){
							continue;
						}

						if(hw_source_filter.value === "manual" && !scenario.connection_type.includes("custom")){
							continue;
						}
					}

					if(has_collisions_filter && has_collisions_filter.value){
						if(has_collisions_filter.value === "true" && !scenarios_to_collisions_map[scenario.id]){
							continue;
						}

						if(has_collisions_filter.value === "false" && scenarios_to_collisions_map[scenario.id]){
							continue;
						}
					}
								

					if (connector_filter && isNonEmptyArray(connector_filter)) {
						if (connector_status_filter && connector_status_filter.id === "applied") {
							if (!(connectors.some(x => connector_filter.some(y => y.id === x)) || (!connectors.length && connector_filter.some(y => y.id === "")))) {
								continue;
							}
						}

						if (connector_status_filter && connector_status_filter.id === "not_applied") {
							if (connectors.some(x => connector_filter.some(y => y.id === x)) || (!connectors.length && connector_filter.some(y => y.id === ""))) {
								continue;
							}
						}
					}
				}

				let scenario_col = {
					"error_in_row": scenarios_to_collisions_map[scenario.id],
					"id": scenario.id,
					"checked": false,
					"scenario_type": get_readable_scenario_type(scenario.connection_type),
					"scenario_length": scenario.total_length==undefined || isNaN(Number(scenario.total_length))?"N/A":Number(scenario.total_length).toFixed(1),
					"modular_unit": modular_unit,
					"panel_1_type": panel_1_type.name || "",
					"panel_1_face": scenario.attached_panels && scenario.attached_panels.length ? scenario.attached_panels[0].side : '-',
					"panel_2_type": panel_2_type.name || "",
					"panel_2_face": scenario.attached_panels && scenario.attached_panels.length > 1 ? scenario.attached_panels[1].side : '-',
					"connectors": connectors.length,
					"info": 
					<div className='flex_property inf-gap-2'>
						<Tooltip trigger={['click']} placement="bottomRight" overlayStyle={{ width: '400px', maxWidth: 'unset' }} overlayInnerStyle={{ maxHeight: '500px', overflow: 'auto' }} title={
							<div className="flex_column inf-gap-2">
								{
									scenario.attached_panels && scenario.attached_panels.length ?
										<>
											<div className="flex_property inf-justify-between">Panel 1: <div style={{ fontWeight: 500 }}>{scenario.attached_panels[0].unique_label} {panel_types_map[scenario.attached_panels[0].panel_type] && panel_types_map[scenario.attached_panels[0].panel_type].name}</div></div>
											<div className="flex_property inf-justify-between">Face 1: <div style={{ fontWeight: 500 }}>{scenario.attached_panels[0].side}</div></div>
										</>
										: ''
								}
								{
									scenario.attached_panels && scenario.attached_panels.length > 1 ?
										<>

											<div className="flex_property inf-justify-between">Panel 2: <div style={{ fontWeight: 500 }}>{scenario.attached_panels[1].unique_label} {panel_types_map[scenario.attached_panels[1].panel_type] && panel_types_map[scenario.attached_panels[1].panel_type].name}</div></div>
											<div className="flex_property inf-justify-between">Face 2: <div style={{ fontWeight: 500 }}>{scenario.attached_panels[1].side}</div></div>
										</>
										: ''
								}
								{
									<div className="flex_property inf-justify-between">Connector Source: <div style={{ fontWeight: 500 }}>{scenario.connection_type && scenario.connection_type.includes("custom") ? "Manual" : "Rule"}</div></div>
								}
								{
									scenario.connection_type && scenario.connection_type.includes("default") && Object.keys(scenario.source_rule_mapping).length ?
										<>
											<ITable rowSeparated={true} coloumnSeparated={true} style_container={{ border: '1px solid var(--light-border-color)' }}>
												<ITableHeader>
													<ITH>Connector</ITH>
													<ITH>Rule Name</ITH>
												</ITableHeader>
												<ITableBody>
													{
														Object.keys(scenario.source_rule_mapping).map(connector_id => (
															<ITableRow>
																<ITD>{modal_json.available_connectors.find(x => x.id === connector_id).name}</ITD>
																<ITD>{scenario.source_rule_mapping[connector_id]}</ITD>
															</ITableRow>
														))
													}
												</ITableBody>
											</ITable>
										</>

										: ''
								}
							</div>
						}>
							<Icons className={'inf-p-1'} onClick={(e) => e.stopPropagation()} name={'info'}></Icons>
						</Tooltip>
						{
							scenarios_to_collisions_map[scenario.id] ?
								<Tooltip trigger={['click']} placement="bottomRight" overlayStyle={{ width: '400px', maxWidth: 'unset' }} overlayInnerStyle={{ maxHeight: '500px', overflow: 'auto' }} title={get_collision_tooltip(scenarios_to_collisions_map[scenario.id])}>
									<Icons className={'inf-p-1'} onClick={(e) => e.stopPropagation()} name={'warning'} style={{cursor: 'pointer', color: '#BF2600', fontSize: '16px'}}></Icons>
								</Tooltip>
							: ''
						}
					</div>,
					"action": {
						menu: {
							onClick: ({ key }) => { onClickAction(key, scenario) },
							items: [
								{
									key: "edit",
									label: "Edit connector"
								},
								{
									key: "change",
									label: "Change connector"
								},
								{
									key: "add",
									label: "Add connector"
								},
								{
									key: "remove",
									label: "Remove connector"
								}
							]
						}
					}
				}

				is_scenario_visible[scenario.id] = true;

				scenarios.push(scenario_col);
				filtered_scenarios_array.push(scenario);
			}
		}

		filtered_scenarios.units = scenarios;

		console.log("scenarios table filtered_scenarios - ", filtered_scenarios)

		// return filtered_scenarios

		let filtered_connectors = [];

		if (modal_json && isNonEmptyArray(modal_json.available_connectors)) {
			filtered_connectors = modal_json.available_connectors.filter(x => {
				if (isNonEmptyArray(x.scenarios_being_used_in)) {
					let is_connector_used = false;
					for (let i in x.scenarios_being_used_in) {
						let current_scenario = x.scenarios_being_used_in[i];
						if (is_scenario_visible[current_scenario]) {
							is_connector_used = true;
							break;
						}
					}

					return is_connector_used
				}
				return false
			}).sort((a, b) => a.name.localeCompare(b.name));
		}

		let scenarios_with_no_connector = filtered_scenarios_array.filter(x => !x.cam_applicator_ids.length);

		return { display_scenarios: filtered_scenarios, display_connectors: filtered_connectors, display_scenarios_array: filtered_scenarios_array, scenarios_with_no_connector };

	}, [modal_json, applied_filters])

	const isTableRowVisibleCallback = useCallback((unit) => {
		return !debounced_search_string_scenarios || includes_search_string(unit.scenario_type, debounced_search_string_scenarios) || includes_search_string((unit.modular_unit && unit.modular_unit.unique_label), debounced_search_string_scenarios) || includes_search_string(unit.panel_1_type, debounced_search_string_scenarios) || includes_search_string(unit.panel_2_type, debounced_search_string_scenarios)
	}, [debounced_search_string_scenarios])

	const isConnectorCardVisibleCallback = useCallback((connector, sku_details) => {
		return !debounced_search_string_connectors || includes_search_string(connector.name, debounced_search_string_connectors) || includes_search_string(sku_details.sku_name, debounced_search_string_connectors)
	}, [debounced_search_string_connectors])

	const getVisibleScenarios = useCallback(() => {
		return display_scenarios.units.filter(isTableRowVisibleCallback)
	}, [display_scenarios, debounced_search_string_scenarios])

	const getVisibleConnectors = useCallback(() => {
		return display_connectors.filter((x) => {
			let sku_details = JSON.parse(window.Module.get_sku_details_from_core(x.sku_id));
			return isConnectorCardVisibleCallback(x, sku_details)
		})
	}, [display_connectors, debounced_search_string_connectors])

	const getConnectorCollisionTooltip = (connector) => {
		let collisions = modal_json && modal_json.collisions;
		let connector_collisions = isNonEmptyArray(collisions) && collisions.filter(x => x.connector === connector.id) || [];

		if(connector_collisions.length){
			return get_collision_tooltip(connector_collisions);
		}
		
		return "";
	}


	const onClickEditConnector = useCallback((connector) => {
		set_pre_selected_connector(connector);
		set_active_scenarios(display_scenarios_array.filter(x => x.cam_applicator_ids.includes(connector.id)));
		set_mode("edit")
	}, [display_scenarios_array])

	const resetOnActionClose = () => {
		set_mode("");
		set_pre_selected_connector();
	}

	// Defines useEffects here, use uef for template
	useEffect(() => {
		// if (modal_type === 'cam_flow' && modal_json) {
			if(modal_json.preselected_modular_units){
				set_applied_filters({
					"modular_units": modal_json.modular_units_json.filter(x => modal_json.preselected_modular_units.includes(x.id))
				})
			}
		// }
	}, []);

	useEffect(() => {
		const debouncedFn = debounce((value) => {
			set_debounced_search_string_scenarios(value);
		}, 500);

		debouncedFn(search_string_scenarios);

		// Cleanup the debounced function on unmount or when search_string_scenarios changes
		return () => {
			debouncedFn.cancel();
		};
	}, [search_string_scenarios]);

	useEffect(() => {
		const debouncedFn = debounce((value) => {
			set_debounced_search_string_connectors(value);
		}, 500);

		debouncedFn(search_string_connectors);

		// Cleanup the debounced function on unmount or when search_string_connectors changes
		return () => {
			debouncedFn.cancel();
		};
	}, [search_string_connectors]);

	// Define derived variables
	const modal_heading = modal_json && modal_json.heading || "Connector sets in design"

	const checkCollisions = () => {
		set_page_loader({
			show: true,
			text: 'Please wait'
		}); 
		setTimeout(() => {
			let resp = window.Module.cam_flow_modal_calculate_violations_manual_trigger();
			handle_ui_response(resp);
			
			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
		},0)
	}

	return (
		<>
			<ConnectorActionsModal pre_selected_connector={pre_selected_connector} action={mode} resetOnClose={resetOnActionClose} modal_json={modal_json} selected_scenarios={active_scenarios} />
			<Modal isOpen={modal_type === 'cam_flow'} style={{ maxWidth: '90vw' }} centered toggle={onclick_handle_close}>
				<IModalHeader toggle={onclick_handle_close} >{modal_heading}</IModalHeader>

				<IModalBody style={{ display: 'flex', height: '80vh', padding: '0px' }}>
					<ConnectorSetModalFilters applied_filters={applied_filters} set_applied_filters={set_applied_filters} modal_json={modal_json} />
					<div className='connector_set_modal_data_container'>
						<div className='connector_set_modal_data_container_nav'>
							<UncontrolledINavbar style_container={{ margin: '0px' }} custom_classname={"inf_nav_tabs_blue_background_1"} tabs={[{ id: "connector_view", name: "Connector view" }, { id: "scenario_view", name: "Scenario view" }]} display_identifier={"name"} set_active_tab={set_active_tab} />
							<div style={{display: 'flex', background: 'white', borderRadius: '8px'}}>
								{filterApplied.current ? <div style={{padding: '4px 8px', background: '#FFFAE6', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px'}}>Filter Result: </div> : ''}
								<div className='connector_set_modal_data_container_nav_info'>
									{/* Info */}
									<div>{filterApplied.current ? 'Total filtered scenarios' : `Total in design: Scenarios`}&nbsp;<b>({display_scenarios_array && display_scenarios_array.length || 0})</b></div>
									{/* <div>{`Unique units: (${modal_json && modal_json.modular_units_json && modal_json.modular_units_json.length || 0})`}</div> */}
									<div>{`No connector applied:`}&nbsp;<b>({scenarios_with_no_connector && scenarios_with_no_connector.length || 0})</b></div>
								</div>
							</div>
						</div>
						<div className='connector_set_modal_data_container_header'>
							{
								active_tab == 0 ?
									<>
										<div>{`Showing ${getVisibleConnectors().length}/${display_connectors.length} unique connectors`}</div>
										<ISearch style_outer={{ width: '360px', margin: '0px' }} placeholder={"Connector name/Sku Name"} getSearchString={set_search_string_connectors} />
										{/* <Button className='primary_button_default'>Add Connector</Button> */}
									</>
									: active_tab == 1 ?
										<>
											<div>{`Showing ${getVisibleScenarios().length}/${display_scenarios && isNonEmptyArray(display_scenarios.units) && display_scenarios.units.length || 0} scenarios`}</div>
											<ISearch style_outer={{ width: '360px', margin: '0px' }} placeholder={"Search by unit / panel names"} getSearchString={set_search_string_scenarios} />
											<AntDropdown trigger={['click']} menu={{
												onClick: onClickBulkAction,
												items: [
													{
														key: "edit",
														label: <div>Edit connector</div>
													},
													{
														key: "change",
														label: <div>Change connector</div>
													},
													{
														key: "add",
														label: <div>Add connector</div>
													},
													{
														key: "remove",
														label: <div>Remove connector</div>
													}
												]
											}}>
												<Button className='secondary_button_default' style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
													Actions
													<Icons name={"chevron_left"} style={{ fontSize: '10px', transform: "rotate(270deg)" }} />
												</Button>
											</AntDropdown>
										</>
										: ''
							}
						</div>
						<div className='connector_set_modal_data'>
							{
								active_tab == 0 ?
								<Masonry
									breakpointCols={{
										default: 3,
										1100: 2,
										700: 1
									}}
									className="masonry-grid"
									columnClassName="masonry-grid-column"
									style={masonryStyles}
								>
									{/* <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', gap: '16px' }}> */}
										{
											display_connectors.map((connector) => (
												// isNonEmptyArray(connector.scenarios_being_used_in) ?
												<ConnectorSetCard connector={connector} onClickEditConnector={onClickEditConnector} isConnectorCardVisible={isConnectorCardVisibleCallback} collision_info={getConnectorCollisionTooltip(connector)} modal_json={modal_json}/>
												// : ''
											))
											// :''
										}
									{/* </div> */}
								</Masonry>
									: active_tab == 1 ?
										<CoreControlledTable table_data={display_scenarios} handle_ui_response={handle_ui_response} getModifiedTableData={set_table_data} isRowVisible={isTableRowVisibleCallback} />
										: ''

							}
						</div>
					</div>
				</IModalBody>
				<IModalFooter>
					<Button className='primary_button_default' onClick={checkCollisions}>Check for collisions</Button>
				</IModalFooter>


			</Modal>
		</>
	)
}


const ChangeConnector = ({ connector, all_connectors, chosenConnector, setChosenConnector }) => {
	// const [selected_option, set_selected_option] = useState("");
	const [ search_string, set_search_string ] = useState('');
	const [ is_connector_selector_menu_open, set_is_connector_selector_menu_open ] = useState(false);

	const isConnectorCardVisible = (connector, sku_details) => {
		return !search_string || includes_search_string(connector.name, search_string) || includes_search_string(sku_details.sku_name, search_string)
	}

	const getVisibleConnectors = (connectors) => {
		return connectors.filter((x) => {
			let sku_details = JSON.parse(window.Module.get_sku_details_from_core(x.sku_id));
			return isConnectorCardVisible(x, sku_details)
		})
	}

	const applicable_connectors = getVisibleConnectors(all_connectors.filter(x => x.applicator_type === connector.applicator_type && x.id !== connector.id));
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
			<div className='inf-flex inf-gap-8' style={{minHeight: '300px'}}>
				<div>
					<div className='inf-font-bold inf-mb-4'>Change from</div>
					<div style={{borderRadius: '8px', overflow: 'hidden'}} className='inf-border-dark'>
						<ApplicatorCard style={{ width: '400px' }} o={connector} />
					</div>
				</div>
				<img style={{visibility: chosenConnector && chosenConnector.id ? 'visible' : 'hidden'}} src='/resources/images/to_arrow.svg' />
				<div>
					<div className='inf-font-bold inf-mb-4'>To</div>
					<Tooltip open={is_connector_selector_menu_open} onOpenChange={(open) => {set_is_connector_selector_menu_open(open)}} trigger={['click']} color='white' overlayStyle={{ maxWidth: 'unset'}} overlayInnerStyle={{maxHeight: '400px', overflowY: 'auto', color: 'unset', padding: '8px'}} placement='bottomLeft' showArrow={false} destroyTooltipOnHide={true}
						title={
							// onClick: ({ key }) => { setChosenConnector(isNonEmptyArray(all_connectors) && all_connectors.find(x => x.id === key)) },
							<div className='flex_column inf-gap-1'>
								<div className='inf-p-2'><ISearch onMouseDown={(e) => e.stopPropagation()} style_outer={{ width: '100%', margin: '0px' }} placeholder={"Connector name/Sku Name"} getSearchString={set_search_string} /></div>
								{
									applicable_connectors.map((connector) => (
										<div className='inf-p-2 gray_hover rounded' onClick={() => { setChosenConnector(connector); set_is_connector_selector_menu_open(false) }}><div style={{ borderRadius: '8px', overflow: 'hidden' }} className='inf-border-dark'><ApplicatorCard style={{ width: '400px' }} o={connector} /></div></div>
									))
								}
							</div>
						}
					>
						<div className='inf-border inf-px-3 inf-py-2 flex_between cp' style={{background: 'white', color:'#A7A8B2', fontWeight: 500, fontSize: '12px', width: '400px'}}>
							Choose the desired connector to change
							<Icons name={'chevron_left'} style={{transform: 'rotate(270deg)', fontSize: '10px'}}/>
						</div>
					</Tooltip>
					{
						chosenConnector && chosenConnector.id &&
						<div style={{borderRadius: '8px', overflow: 'hidden'}} className='inf-border-dark inf-mt-4'>
							<ApplicatorCard style={{ width: '400px' }} o={chosenConnector} />
						</div>
					}
				</div>
			</div>
		</div>
	)
}

const ChooseConnector = ({ connectors, set_connectors, connector_type, actionMessage, chosenConnector, setChosenConnector, allow_create, show_tabs, idOnly=true, isMulti=false }) => {
	// const [selected_option, set_selected_option] = useState("");
	const [ search_string, set_search_string ] = useState('');
	const [ active_tab, set_active_tab ] = useState(() => {
		if(connectors && connectors.length){
			if(connectors.find(x => !x.is_fe)){
				return "published"
			}else if(connectors.find(x => x.is_fe)){
				return "local"
			}
		}

		return "published"
	});
	
	const isConnectorCardVisible = (connector, sku_details) => {
		return !search_string || includes_search_string(connector.name, search_string) || includes_search_string(sku_details.sku_name, search_string)
	}

	const changeTab = (id) => {
		set_active_tab(id);
		set_search_string("");
	}

	const isConnectorSelected = (connector) => {
		if(!isMulti){
			return !idOnly ? chosenConnector.id === connector.id : chosenConnector === connector.id;
		}else{
			if(isNonEmptyArray(chosenConnector)){
				return chosenConnector.find(x => (idOnly ? x : x.id) === connector.id);
			}else{
				return false;
			}
		}
	}

	const handleSelection = (connector) => {
		if(!isMulti){
			setChosenConnector(!idOnly ? connector : connector.id);
		}else{
			if(isNonEmptyArray(chosenConnector)){
				if(isConnectorSelected(connector)){
					setChosenConnector(chosenConnector.filter(x => (idOnly ? x : x.id) !== connector.id));
				}else{
					setChosenConnector([...chosenConnector, (idOnly ? connector.id : connector)]);
				}
			}else{
				setChosenConnector([idOnly ? connector.id : connector]);
			}
		}
	}

	const display_connectors = isNonEmptyArray(connectors) ? connectors.filter((x) => {
		let sku_details = JSON.parse(window.Module.get_sku_details_from_core(x.sku_id));
		return isConnectorCardVisible(x, sku_details)
	}) : []

	const global_connectors = display_connectors.filter(x => !x.is_fe)
	const local_connectors = display_connectors.filter(x => x.is_fe)

	return (
		// <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
		isNonEmptyArray(connectors) || allow_create ? 
		<React.Fragment>
			<div className='inf-mb-3 inf-font-semibold'>{actionMessage}</div>
			{
				allow_create ?
					<ITriggerModal className='inf-mb-3' ModalComponent={CreateConnector} modalProps={{ applicator_type: connector_type, onSubmit: (connector) => { set_connectors((prev) => { let curr = [...prev]; curr.unshift(connector); return curr }); handleSelection(connector); changeTab("local") } }}>
						<IButton outline={true} style={{ width: '100%' }}>
							+ Create New Connector
						</IButton>
					</ITriggerModal>
				: ''
			}
			{show_tabs ? <INavBar custom_classname={"inf_nav_tabs_blue_background_1"} tabs={[{ id: "published", name: `Published to Admin (${global_connectors.length})` }, { id: "local", name: `Created within Design (${local_connectors.length})` }]} activeTab={active_tab} handleTabClick={changeTab} /> : ''}
			<ISearch key={active_tab} style_outer={{ width: '100%', margin: '0px', marginBottom: '16px' }} placeholder={"Connector name/Sku Name"} getSearchString={set_search_string} />
			<div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', gap: '32px' }}>
				{
					(show_tabs ? (active_tab === "published" ? global_connectors : local_connectors) : display_connectors).map((connector) => (
						// isNonEmptyArray(connector.scenarios_being_used_in) ?
						<ConnectorSetSelectionCard connector={connector} checked={isConnectorSelected(connector)} onSelect={() => { handleSelection(connector) }} isMulti={isMulti}/>
						// : ''
					))
					// :''
				}
			</div>
		</React.Fragment>
		:
		<div className='flex_center' style={{width: '100%', height: '100%'}}>
			No Common Connectors Found
		</div>
		// </div>
	)
}

export const CreateConnector = ({open, handle_close, applicator_type, onSubmit}) => {
	const alert = useAlert();

	const [ connector, set_connector ] = useState({
		id: window.Module.uuidv4(),
		name: "New connector",
		application_method: "free",
		applicator_type: "dowel_hinge",
		placement_styles: [
			[0, {
				repeat_distance: "50",
				offsets: [],
				min_start_distance: "100",
				min_end_distance: "100",
				assumed_panel_depth: 500,
				assumed_panel_width: 500,
				assumed_panel_thickness: 30
			}]
		],
		sku_id: "",
		newly_created: true,
		is_fe: true
	});

	useEffect(() => {
		set_connector((prev) => {return {...prev, applicator_type}});
	}, []);

	const select_sku_callback = (args) => {
        var applicator_clone = _deref(connector);
        applicator_clone.sku_id = args;
        set_connector(applicator_clone);
    }

    const open_select_sku_modal = () => {
        window.application_carousel_selection_interface.callback = select_sku_callback;
        window.Module.open_application_sku_selector_modal("\"\"");
        window.update_view();
    }

	const submit_connector = () => {
		if(!connector.sku_id){
			alert.error("Please select a SKU");
			return;
		}
		onSubmit(connector);
		handle_close();
	}

	return(
		<Modal centered isOpen={open} toggle={handle_close} size='xl'>
			<IModalHeader toggle={handle_close}>Create connector</IModalHeader>
			<IModalBody>
				<ModifyConnector mode={"add"} connector_type={applicator_type} sku_selection_onclick={open_select_sku_modal} curr_applicator={connector} set_curr_applicator={set_connector} />
			</IModalBody>
			<IModalFooter>
				<IButton onClick={submit_connector}>Create</IButton>
			</IModalFooter>
		</Modal>
	)
}

const ChooseConnectorToAdd = ({ connector_type, connectors, set_connectors, actionMessage, chosenConnector, setChosenConnector }) => {
	// const [selected_option, set_selected_option] = useState("");
	// const [ connectors_local, set_connectors_local ] = useState([]);

	// useEffect(() => {
	// 	set_connectors_local(connectors)
	// }, [connectors]);

	const [ search_string, set_search_string ] = useState('');
	
	const isConnectorCardVisible = (connector, sku_details) => {
		return !search_string || includes_search_string(connector.name, search_string) || includes_search_string(sku_details.sku_name, search_string)
	}

	const display_connectors = isNonEmptyArray(connectors) ? connectors.filter((x) => {
		let sku_details = JSON.parse(window.Module.get_sku_details_from_core(x.sku_id));
		return isConnectorCardVisible(x, sku_details)
	}) : []

	return (
		// <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
		<React.Fragment>
			{actionMessage}
			<ISearch style_outer={{ width: '100%', margin: '0px', marginBottom: '16px' }} placeholder={"Connector name/Sku Name"} getSearchString={set_search_string} />
			<ITriggerModal className='inf-mb-3' ModalComponent={CreateConnector} modalProps={{ applicator_type: connector_type, onSubmit: (connector) => {set_connectors((prev) => {let curr = [...prev]; curr.unshift(connector); return curr}); setChosenConnector(connector)}}}>
				<IButton outline={true} style={{width: '100%'}}>
					+ Create New Connector
				</IButton>
			</ITriggerModal>
			<div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', gap: '16px' }}>
				
				{
					display_connectors.map((connector) => (
						// isNonEmptyArray(connector.scenarios_being_used_in) ?
						<ConnectorSetSelectionCard connector={connector} checked={(chosenConnector && chosenConnector.id) === connector.id} onSelect={() => { setChosenConnector(connector) }} />
						// : ''
					))
					// :''
				}
			</div>
		</React.Fragment>
		// </div>
	)
}

const ConfirmScenarios = ({ selected_scenarios, all_scenarios, actionMessage, confirmedScenario, setConfirmedScenario }) => {
	// const [selected_option, set_selected_option] = useState("");
	let all_scenarios_selected = all_scenarios.length === selected_scenarios.length;
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', gap: '24px' }}>
			{
				// !all_scenarios_selected ?
				<>
				{actionMessage}
				<div className='flex_property inf-gap-6'>
				
				{
				!all_scenarios_selected ?

					<div className='confirm_scenarios_card' onClick={() => setConfirmedScenario("selected_scenarios")}>
					<Checkbox checked={confirmedScenario === "selected_scenarios"}>Apply on selected scenarios</Checkbox>
					<div>
						<div>Selected scenarios</div>
						<div style={{ fontWeight: 700 }}>{selected_scenarios.length} scenarios</div>
					</div>
					<img width={'268px'} src='/resources/images/selected_scenarios_thumbnail.svg' />
				</div>
				: ''
}
				<div className='confirm_scenarios_card' onClick={() => setConfirmedScenario("all_scenarios")}>
					<Checkbox checked={confirmedScenario === "all_scenarios"}>Apply on all scenarios</Checkbox>
					<div>
						<div>Total scenarios</div>
						<div style={{ fontWeight: 700 }}>{all_scenarios.length} scenarios in whole design</div>
					</div>
					<img width={'268px'} src='/resources/images/all_scenarios_thumbnail.svg' />
				</div>
			</div>
				</>
			// :
			// <div className='flex_center'>
			// 	{/* Provide Info that selected scenario and all scenario are same */}
			// 	<div className='inf-text-lg inf-font-bold'>All scenarios are selected</div>
			// </div>
			}
		</div>
	)
}
const VerifyScenariosTable = ({ scenarios, panel_type_options }) => {
	
	const panel_types_map = {};
	panel_type_options.map(x => {
		panel_types_map[x.value] = x;
	})

	return (
		<ITable coloumnSeparated={true} rowSeparated={true} bordered={true} striped={true}>
			<ITableHeader>
				<ITH>Sno</ITH>
				<ITH>Panel 1</ITH>
				<ITH>Panel 2</ITH>
			</ITableHeader>
			<ITableBody>
				{
					isNonEmptyArray(scenarios) ? scenarios.map((scenario, idx) => (
						<ITableRow>
							<ITD>{Number(idx) + 1}</ITD>
							<ITD>{scenario.attached_panels&&scenario.attached_panels[0]?(scenario.attached_panels[0].unique_label + " (" + (panel_types_map[scenario.attached_panels[0].panel_type] && panel_types_map[scenario.attached_panels[0].panel_type].name || scenario.attached_panels[0].panel_type) + ")"):"-"}</ITD>
							<ITD>{scenario.attached_panels&&scenario.attached_panels[1]?(scenario.attached_panels[1].unique_label + " (" + (panel_types_map[scenario.attached_panels[1].panel_type] && panel_types_map[scenario.attached_panels[1].panel_type].name || scenario.attached_panels[1].panel_type) + ")"):"-"}</ITD>
						</ITableRow>
					))
						: ''
				}
			</ITableBody>
		</ITable>
	)
}

const VerifyAndRemove = ({ connector, scenarios, panel_type_options }) => {
	return (
		<div>
			<b>Connector to be removed</b>
			<div className='flex_property'>
				<div style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #FF5247' }}>
					<ApplicatorCard style={{ width: '400px' }} o={connector} />
				</div>
			</div>

			<div className='inf-mt-4'>

				<div className='mb-2'>
					<b>List on which connector will be removed</b>
				</div>
				<VerifyScenariosTable scenarios={scenarios} panel_type_options={panel_type_options}/>
			</div>
		</div>
	)
}

const VerifyAndEdit = ({ connector, edited_connector, scenarios, panel_type_options }) => {
	return (
		<div>
			<div className='flex_property inf-gap-8'>
				<div className='flex-1-mw'>
					<b>Before Edit</b>
					<div style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #FF5247' }}>
						<ApplicatorCard o={connector} />
					</div>
				</div>
				<img src='/resources/images/to_arrow.svg' />
				<div className='flex-1-mw'>
					<b>After Edit</b>
					<div style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #23C16B' }}>
						<ApplicatorCard o={edited_connector} />
					</div>
				</div>
			</div>

			<div className='inf-mt-4'>

				<div className='mb-2'>
					<b>List on which the changes will be applied</b>
				</div>
				<VerifyScenariosTable scenarios={scenarios} panel_type_options={panel_type_options}/>
			</div>
		</div>
	)
}

const VerifyAndChange = ({ connector, new_connector, scenarios, panel_type_options }) => {
	return (
		<div>
			<div className='flex_property inf-gap-8'>
				<div className='flex-1-mw'>
					<b>Changed from</b>
					<div style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #FF5247' }}>
						<ApplicatorCard o={connector} />
					</div>
				</div>
				<img src='/resources/images/to_arrow.svg' />
				<div className='flex-1-mw'>
					<b>To</b>
					<div style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #23C16B' }}>
						<ApplicatorCard o={new_connector} />
					</div>
				</div>
			</div>

			<div className='inf-mt-4'>

				<div className='mb-2'>
					<b>List on which the changes will be applied</b>
				</div>
				<VerifyScenariosTable scenarios={scenarios} panel_type_options={panel_type_options}/>
			</div>
		</div>
	)
}

const VerifyAndAdd = ({ new_connector, scenarios, panel_type_options }) => {
	return (
		<div>
			<b>Connectors to be added</b>
			<div className='flex_property inf-gap-2 inf-py-2' style={{overflow: 'auto'}}>
				{
					new_connector.map((connector) => (

						<div style={{ borderRadius: '8px', overflow: 'hidden', border: '1px solid #23C16B', flexShrink: 0 }}>
							<ApplicatorCard style={{ width: '400px' }} o={connector} />
						</div>))
				}
			</div>

			<div className='inf-mt-4'>

				<div className='mb-2'>
					<b>List on which the changes will be applied</b>
				</div>
				<VerifyScenariosTable scenarios={scenarios} panel_type_options={panel_type_options}/>
			</div>
		</div>
	)
}

const ConnectorActionsModal = ({ action, resetOnClose, selected_scenarios, pre_selected_connector, modal_json }) => {
	const [remove_modal_open, set_remove_modal_open] = useState(false);
	const handle_close_remove = () => { set_remove_modal_open(false); resetOnClose() }
	const open_remove = () => { set_remove_modal_open(true) }

	const [edit_modal_open, set_edit_modal_open] = useState(false);
	const handle_close_edit = () => { set_edit_modal_open(false); resetOnClose() }
	const open_edit = () => { set_edit_modal_open(true) }

	const [change_modal_open, set_change_modal_open] = useState(false);
	const handle_close_change = () => { set_change_modal_open(false); resetOnClose() }
	const open_change = () => { set_change_modal_open(true) }

	const [add_modal_open, set_add_modal_open] = useState(false);
	const handle_close_add = () => { set_add_modal_open(false); resetOnClose() }
	const open_add = () => { set_add_modal_open(true) }

	useEffect(() => {
		if (action === "remove") {
			open_remove()
		}
		if (action === "edit") {
			open_edit()
		}
		if (action === "change") {
			open_change()
		}
		if (action === "add") {
			open_add()
		}
	}, [action]);

	return (
		<>
			{["add"].includes(action) ? <AddConnectorModal selected_scenarios={selected_scenarios} modal_json={modal_json} open={add_modal_open} handle_close={handle_close_add} /> : ''}
			{["change"].includes(action) ? <ChangeConnectorModal selected_scenarios={selected_scenarios} modal_json={modal_json} open={change_modal_open} handle_close={handle_close_change} /> : ''}
			{["edit"].includes(action) ? <EditConnectorModal pre_selected_connector={pre_selected_connector} collisions={modal_json?modal_json.collisions:[]} selected_scenarios={selected_scenarios} modal_json={modal_json} open={edit_modal_open} handle_close={handle_close_edit} /> : ''}
			{["remove"].includes(action) ? <RemoveConnectorModal selected_scenarios={selected_scenarios} modal_json={modal_json} open={remove_modal_open} handle_close={handle_close_remove} /> : ''}
		</>
	)
}

function getCommonCamApplicators(selected_scenarios, all_connectors) {
	// If selected_scenarios is empty or contains no cam_applicator_ids, return an empty array
	if (selected_scenarios.length === 0) return [];

	// Initialize a Map to track the count of cam_applicator_ids in all objects
	const idCountMap = new Map();

	// Iterate over all objects and count each cam_applicator_id
	selected_scenarios.forEach(obj => {
		obj.cam_applicator_ids.forEach(id => {
			// Increment the count for the current id
			idCountMap.set(id, (idCountMap.get(id) || 0) + 1);
		});
	});

	return all_connectors.filter(x => idCountMap.get(x.id) === selected_scenarios.length)

}

const getScenarioType = (scenario) => {
	let type = scenario.connection_type.split('_')
	type.shift();
	type = type.join("_")

	if (type === "dowel" || type == "hinge") {
		return "dowel_hinge";
	} else {
		return type;
	}
}

const get_commpon_connector_type = (selected_scenarios) => {
	if (selected_scenarios.length === 0) return "";

	let expected_type = getScenarioType(selected_scenarios[0]);

	for (let i in selected_scenarios) {
		let scenario = selected_scenarios[i]
		if (getScenarioType(scenario) !== expected_type) {
			return ""
		}
	}

	return expected_type;
}

function getAllEligibleCamApplicators(selected_scenarios, all_connectors) {
	// If selected_scenarios is empty or contains no cam_applicator_ids, return an empty array
	let type = get_commpon_connector_type(selected_scenarios)
	if (!type) return [];

	let already_present_connectors = new Set();
	selected_scenarios.forEach(scenario => {
		scenario.cam_applicator_ids.forEach(cam_applicator_id => {
			already_present_connectors.add(cam_applicator_id);
		})
	})

	return all_connectors.filter(x => x.applicator_type === type && !already_present_connectors.has(x.id))

}

const getConfirmedScenario = (confirmed_scenario, selected_scenarios, all_scenarios) => {
	if (confirmed_scenario === "all_scenarios") {
		return all_scenarios;
	}

	if (confirmed_scenario === "selected_scenarios") {
		return selected_scenarios;
	}

	return [];
}

const updateConnectorSetDetails = async (stringified_connector_scenario_data) => {
	try {
		console.log("final data - ", JSON.parse(stringified_connector_scenario_data));
		let resp = await window.Promisify(window.Module.common_onchange_function_for_cam_flow(stringified_connector_scenario_data))
		window.handle_ui_response(resp);
	} catch (err) {
		console.error(`Error in updateConnectorSetDetails `, err)
		handleError(err);
	}
}

export const RemoveConnectorModal = ({ selected_scenarios, modal_json, open, handle_close }) => {
	const steps = [{ title: 'Choose Connector' }, { title: 'Confirm Scenarios' }, { title: 'Verify and remove' }];
	const [step, set_step] = useState(0);
	const [confirmed_scenario, set_confirmed_scenario] = useState('all_scenarios');
	const [chosen_connector, set_chosen_connector] = useState('');

	const all_scenarios = modal_json && modal_json.available_scenarios || [];
	let all_connectors = modal_json && modal_json.available_connectors || [];
	const all_applicable_scenarios = all_scenarios.filter(x => chosen_connector && x.cam_applicator_ids.includes(chosen_connector))

	const onClickNextDisabled = () => {
		if(step == 0){
			if(!chosen_connector) return "Please choose a connector to proceed to the next step"
		}

		if(step == 1){
			if(!confirmed_scenario) return "Please choose the scenarios to proceed to the next step"
		}
	}

	const onClickBackDisabled = () => {
		if(step == 0){
			return "You are at the first step"
		}
	}

	const onClickNext = async () => {
		if (step === 2) {
			let final_connector_scenario_data = {}
			final_connector_scenario_data.connectors = all_connectors;
			let modified_scenarios = _deref(all_scenarios);
			let confirmed_scenario_ids_map = {};
			let confirmed_scenarios = getConfirmedScenario(confirmed_scenario, selected_scenarios, all_applicable_scenarios)
			for (let i in confirmed_scenarios) {
				let scenario = confirmed_scenarios[i];
				confirmed_scenario_ids_map[scenario.id] = true;
			}
			for (let i in modified_scenarios) {
				let scenario = modified_scenarios[i]
				if (confirmed_scenario_ids_map[scenario.id]) {
					scenario.cam_applicator_ids = scenario.cam_applicator_ids.filter(x => x !== chosen_connector)
				}
			}
			final_connector_scenario_data.scenarios = modified_scenarios;
			await updateConnectorSetDetails(JSON.stringify(final_connector_scenario_data))
			handle_close()

		} else {
			set_step((prev) => prev + 1)
		}
	}
	const onClickBack = () => {
		set_step((prev) => prev - 1)
	}

	let disabled_next_message = onClickNextDisabled();
	let disabled_back_message = onClickBackDisabled();


	return (
		<Modal centered size='xl' isOpen={open} /* toggle={handle_close} */>
			<IModalHeader toggle={handle_close}>Remove Connector</IModalHeader>
			<IModalBody style={{ padding: '0px', height: '550px', maxHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
				<div style={{ width: '100%', borderBottom: '1px solid #C5C7CF', borderTop: '1px solid #C5C7CF', display: 'flex', justifyContent: 'center', gap: '24px', padding: '12px' }}>
					<Steps current={step} items={steps} />
				</div>
				<div style={{ flex: 1, minHeight: '0px', overflow: 'auto', padding: '16px' }}>
					{
						step === 0 ?
							<ChooseConnector connectors={getCommonCamApplicators(selected_scenarios, all_connectors)} chosenConnector={chosen_connector} setChosenConnector={set_chosen_connector} />
							: ''
					}
					{
						step === 1 ?
							<ConfirmScenarios selected_scenarios={selected_scenarios} all_scenarios={all_applicable_scenarios} actionMessage={"Choose where to remove this connector"} confirmedScenario={confirmed_scenario} setConfirmedScenario={set_confirmed_scenario} />
							: ''
					}
					{
						step === 2 ?
							<VerifyAndRemove connector={all_connectors.find(x => x.id === chosen_connector)} scenarios={getConfirmedScenario(confirmed_scenario, selected_scenarios, all_applicable_scenarios)} panel_type_options={modal_json.panel_type_options}/>
							: ''
					}
				</div>
			</IModalBody>
			<IModalFooter>
				{step > 0 ? <Button disabled={disabled_back_message} className='secondary_button_default' onClick={onClickBack}>Back</Button> : ''}
				<Tooltip title={disabled_next_message}>
					<div>
						<Button disabled={disabled_next_message} className='primary_button_default' onClick={onClickNext}>{step === 2?"Confirm & Apply":"Next"}</Button>
					</div>
				</Tooltip>
			</IModalFooter>
		</Modal>
	)
}

export const EditConnectorModal = ({ pre_selected_connector, selected_scenarios, collisions, modal_json, open, handle_close }) => {
	const steps = [{ title: 'Choose Connector' }, { title: 'Edit Connector' }, { title: 'Confirm Scenarios' }, { title: 'Verify and apply' }];
	const [step, set_step] = useState(pre_selected_connector ? 1 :0);
	const [confirmed_scenario, set_confirmed_scenario] = useState('all_scenarios');
	const [chosen_connector, set_chosen_connector] = useState('');
	const [edited_connector_details, set_edited_connector_details] = useState({});

	const all_scenarios = modal_json && modal_json.available_scenarios || [];
	let all_connectors = modal_json && modal_json.available_connectors || [];
	const all_applicable_scenarios = all_scenarios.filter(x => chosen_connector && x.cam_applicator_ids.includes(chosen_connector))

	const get_scenarios_to_collisions_map = () => {
		let scenarios_to_collisions_map = {};
		if(!collisions) return scenarios_to_collisions_map;

		if (isNonEmptyArray(collisions)) {
			for (let i in collisions) {
				let collision = collisions[i];
				if (!scenarios_to_collisions_map[collision.scenario]) {
					scenarios_to_collisions_map[collision.scenario] = [];
				}
				scenarios_to_collisions_map[collision.scenario].push(collision);
			}
		}

		return scenarios_to_collisions_map
	}

	const onClickNextDisabled = () => {
		if(step == 0){
			if(!chosen_connector) return "Please choose a connector to proceed to the next step"
		}

		if(step == 2){
			if(!confirmed_scenario) return "Please choose the scenarios to proceed to the next step"
		}
	}

	const onClickBackDisabled = () => {
		if(step == 0){
			return "You are at the first step"
		}
	}

	const onClickNext = async () => {
		if (step === 3) {
			let final_connector_scenario_data = {};

			final_connector_scenario_data.connectors = _deref(all_connectors);
			let edited_connector_id = /* confirmed_scenario === "all_scenarios" ? edited_connector_details.id : window.Module.uuidv4() */ edited_connector_details.id;
			let edited_connector_details_final = _deref(edited_connector_details);
			edited_connector_details_final.id = edited_connector_id;
			
			// edited_connector_details_final.name = edited_connector_details_final.name + " Copy";
			
			let confirmed_scenarios = getConfirmedScenario(confirmed_scenario, selected_scenarios, all_applicable_scenarios)
			edited_connector_details_final.scenarios_being_used_in = confirmed_scenarios.map(x => x.id);
			if(confirmed_scenario === "all_scenarios"){
				final_connector_scenario_data.connectors = final_connector_scenario_data.connectors.filter(x => x.id !== chosen_connector)
			}else{
				edited_connector_details_final.newly_created = true;
				edited_connector_details_final.is_fe = true;
			}
			final_connector_scenario_data.connectors.push(edited_connector_details_final);

			let modified_scenarios = _deref(all_scenarios);
			let confirmed_scenario_ids_map = {};

			for (let i in confirmed_scenarios) {
				let scenario = confirmed_scenarios[i];
				confirmed_scenario_ids_map[scenario.id] = true;
			}

			for (let i in modified_scenarios) {
				let scenario = modified_scenarios[i]
				if (confirmed_scenario_ids_map[scenario.id]) {
					scenario.cam_applicator_ids = scenario.cam_applicator_ids.filter(x => x !== chosen_connector)
					scenario.cam_applicator_ids.push(edited_connector_id);
				}
			}

			final_connector_scenario_data.scenarios = modified_scenarios;
			await updateConnectorSetDetails(JSON.stringify(final_connector_scenario_data))
			handle_close()

		} else {
			if(step === 2){
				let chosen_connector_details = all_connectors.find(x => x.id === chosen_connector);
				if(confirmed_scenario !== "all_scenarios"){
					if(edited_connector_details.name === chosen_connector_details.name){
						edited_connector_details.name = edited_connector_details.name + " Copy";
					}
					edited_connector_details.is_fe = true;
					edited_connector_details.id = window.Module.uuidv4();
				}else{
					edited_connector_details.is_fe = chosen_connector_details.is_fe;
					edited_connector_details.id = chosen_connector_details.id;
				}

			}
			set_step((prev) => prev + 1)
		}
	}
	const onClickBack = () => {
		set_step((prev) => prev - 1)
	}

	let disabled_next_message = onClickNextDisabled();
	let disabled_back_message = onClickBackDisabled();


	useEffect(() => {
		console.log("edited connector details", edited_connector_details)
		set_edited_connector_details(_deref(all_connectors.find(x => x.id === chosen_connector) || {}))
	}, [chosen_connector]);

	useEffect(() => {
		if (pre_selected_connector && pre_selected_connector.id) {
			set_chosen_connector(pre_selected_connector.id);
		}
	}, []);

	const select_sku_callback = (args) => {
        var applicator_clone = _deref(edited_connector_details);
        applicator_clone.sku_id = args;
        set_edited_connector_details(applicator_clone);
    }

    const open_select_sku_modal = () => {
        window.application_carousel_selection_interface.callback = select_sku_callback;
        window.Module.open_application_sku_selector_modal("\"\"");
        window.update_view();
    }

	return (
		<Modal centered size='xl' isOpen={open}>
			<IModalHeader toggle={handle_close}>Edit Connector</IModalHeader>
			<IModalBody style={{ padding: '0px', height: '550px', maxHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
				<div style={{ width: '100%', borderBottom: '1px solid #C5C7CF', borderTop: '1px solid #C5C7CF', display: 'flex', justifyContent: 'center', gap: '24px', padding: '12px' }}>
					<Steps current={step} items={steps} />
				</div>
				<div style={{ flex: 1, minHeight: '0px', overflow: 'auto', padding: '16px' }}>
					{
						step === 0 ?
							<ChooseConnector connectors={pre_selected_connector && pre_selected_connector.id ? [pre_selected_connector] : getCommonCamApplicators(selected_scenarios, all_connectors)} chosenConnector={chosen_connector} setChosenConnector={set_chosen_connector} />
							: ''
					}
					{
						step === 1 ?
							<ModifyConnector mode={"edit"} selected_scenarios={selected_scenarios} scenarios_to_collisions_map={get_scenarios_to_collisions_map()} sku_selection_onclick={open_select_sku_modal} /* applicator_to_edit={all_connectors.find(x => x.id === chosen_connector)} */ curr_applicator={edited_connector_details} set_curr_applicator={set_edited_connector_details} />
							: ''
					}
					{
						step === 2 ?
							<ConfirmScenarios selected_scenarios={selected_scenarios} all_scenarios={all_applicable_scenarios} actionMessage={"Choose where to edit this connector"} confirmedScenario={confirmed_scenario} setConfirmedScenario={set_confirmed_scenario} />
							: ''
					}
					{
						step === 3 ?
							<VerifyAndEdit connector={all_connectors.find(x => x.id === chosen_connector)} edited_connector={edited_connector_details} scenarios={getConfirmedScenario(confirmed_scenario, selected_scenarios, all_applicable_scenarios)} panel_type_options={modal_json.panel_type_options}/>
							: ''
					}
				</div>
			</IModalBody>
			<IModalFooter>
				{step > 0 ? <Button disabled={disabled_back_message} className='secondary_button_default' onClick={onClickBack}>Back</Button> : '' }
				<Tooltip title={disabled_next_message}>
					<div>
						<Button disabled={disabled_next_message} className='primary_button_default' onClick={onClickNext}>{step === 3?"Confirm & Apply":"Next"}</Button>
					</div>
				</Tooltip>
			</IModalFooter>
		</Modal>
	)
}


export const ChangeConnectorModal = ({ selected_scenarios, modal_json, open, handle_close }) => {
	const steps = [{ title: 'Choose Connector' }, { title: 'Change Connector' }, { title: 'Confirm Scenarios' }, { title: 'Verify and change' }];
	const [step, set_step] = useState(0);
	const [confirmed_scenario, set_confirmed_scenario] = useState('all_scenarios');
	const [chosen_connector, set_chosen_connector] = useState('');
	const [new_connector, set_new_connector] = useState('');

	const all_scenarios = modal_json && modal_json.available_scenarios || [];
	let all_connectors = modal_json && modal_json.available_connectors || [];
	let chosen_connector_details = all_connectors.find(x => x.id === chosen_connector)
	const all_applicable_scenarios = all_scenarios.filter(x => chosen_connector && x.cam_applicator_ids.includes(chosen_connector))
	const applicable_connectors = all_connectors.filter(x => x.applicator_type === (chosen_connector_details && chosen_connector_details.applicator_type) && x.id !== (chosen_connector_details && chosen_connector_details.id));

	const onClickNextDisabled = () => {
		if(step == 0){
			if(!chosen_connector) return "Please choose a connector to proceed to the next step"
		}

		if(step == 1){
			if(!new_connector) return "Please choose a connector to proceed to the next step"
		}

		if(step == 2){
			if(!confirmed_scenario) return "Please choose the scenarios to proceed to the next step"
		}
	}

	const onClickBackDisabled = () => {
		if(step == 0){
			return "You are at the first step"
		}
	}

	const onClickNext = async () => {
		if (step === 3) {
			let final_connector_scenario_data = {};

			final_connector_scenario_data.connectors = _deref(all_connectors);
			let edited_connector_id = new_connector && new_connector.id;

			let modified_scenarios = _deref(all_scenarios);
			let confirmed_scenario_ids_map = {};
			let confirmed_scenarios = getConfirmedScenario(confirmed_scenario, selected_scenarios, all_applicable_scenarios)

			for (let i in confirmed_scenarios) {
				let scenario = confirmed_scenarios[i];
				confirmed_scenario_ids_map[scenario.id] = true;
			}

			for (let i in modified_scenarios) {
				let scenario = modified_scenarios[i]
				if (confirmed_scenario_ids_map[scenario.id]) {
					// Todo - handle conflicts if edited connector is already present
					scenario.cam_applicator_ids = scenario.cam_applicator_ids.filter(x => x !== chosen_connector)
					scenario.cam_applicator_ids.push(edited_connector_id);
				}
			}

			final_connector_scenario_data.scenarios = modified_scenarios;
			await updateConnectorSetDetails(JSON.stringify(final_connector_scenario_data))
			handle_close()

		} else {
			set_step((prev) => prev + 1)
		}
	}
	const onClickBack = () => {
		set_step((prev) => prev - 1)
	}

	let disabled_next_message = onClickNextDisabled();
	let disabled_back_message = onClickBackDisabled();


	return (
		<Modal centered size='xl' isOpen={open} /* toggle={handle_close} */>
			<IModalHeader toggle={handle_close}>Change Connector</IModalHeader>
			<IModalBody style={{ padding: '0px', height: '550px', maxHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
				<div style={{ width: '100%', borderBottom: '1px solid #C5C7CF', borderTop: '1px solid #C5C7CF', display: 'flex', justifyContent: 'center', gap: '24px', padding: '12px' }}>
					<Steps current={step} items={steps} />
				</div>
				<div style={{ flex: 1, minHeight: '0px', overflow: 'auto', padding: '16px' }}>
					{
						step === 0 ?
							<ChooseConnector connectors={getCommonCamApplicators(selected_scenarios, all_connectors)} chosenConnector={chosen_connector} setChosenConnector={set_chosen_connector} />
							: ''
					}
					{
						step === 1 ?
							<ChooseConnector key={"choose"} actionMessage={"Choose the replacement connector"} connector={chosen_connector_details} connectors={applicable_connectors} chosenConnector={new_connector} setChosenConnector={set_new_connector} show_tabs={true} idOnly={false}/>
							: ''
					}
					{
						step === 2 ?
							<ConfirmScenarios selected_scenarios={selected_scenarios} all_scenarios={all_applicable_scenarios} actionMessage={"Choose where to change this connector"} confirmedScenario={confirmed_scenario} setConfirmedScenario={set_confirmed_scenario} />
							: ''
					}
					{
						step === 3 ?
							<VerifyAndChange connector={chosen_connector_details} new_connector={new_connector} scenarios={getConfirmedScenario(confirmed_scenario, selected_scenarios, all_applicable_scenarios)} panel_type_options={modal_json.panel_type_options}/>
							: ''
					}
				</div>
			</IModalBody>
			<IModalFooter>
				{step > 0 ? <Button disabled={disabled_back_message} className='secondary_button_default' onClick={onClickBack}>Back</Button> : ''}
				<Tooltip title={disabled_next_message}>
					<div>
						<Button disabled={disabled_next_message} className='primary_button_default' onClick={onClickNext}>{step === 3?"Confirm & Apply":"Next"}</Button>
					</div>
				</Tooltip>
			</IModalFooter>
		</Modal>
	)
}

export const AddConnectorModal = ({ selected_scenarios, modal_json, open, handle_close }) => {
	const steps = [{ title: 'Add Connector' }, /* { title: 'Confirm Scenarios' }, */ { title: 'Verify and apply' }];
	const [step, set_step] = useState(0);
	const [confirmed_scenario, set_confirmed_scenario] = useState('selected_scenarios');
	const [chosen_connector, set_chosen_connector] = useState('');
	const [ eligible_connectors, set_eligible_connectors ] = useState([]);

	const all_scenarios = modal_json && modal_json.available_scenarios || [];
	const all_applicable_scenarios = all_scenarios.filter(x => (chosen_connector && isNonEmptyArray(chosen_connector) && chosen_connector[0].applicator_type) === getScenarioType(x))
	let all_connectors = modal_json && modal_json.available_connectors || [];

	const onClickNextDisabled = () => {
		if(step == 0){
			if(!chosen_connector) return "Please choose a connector to proceed to the next step"
		}

		// if(step == 1){
		// 	if(!confirmed_scenario) return "Please choose the scenarios to proceed to the next step"
		// }
	}

	const onClickBackDisabled = () => {
		if(step == 0){
			return "You are at the first step"
		}
	}

	const onClickNext = async () => {
		if (step === 1) {
			let final_connector_scenario_data = {};

			final_connector_scenario_data.connectors = _deref(eligible_connectors);
			let edited_connector_id = chosen_connector && chosen_connector.map(x => x.id);
			// let edited_connector_id = chosen_connector && chosen_connector.id;

			let modified_scenarios = _deref(all_scenarios);
			let confirmed_scenario_ids_map = {};
			let confirmed_scenarios = getConfirmedScenario(confirmed_scenario, selected_scenarios, all_applicable_scenarios)

			for (let i in confirmed_scenarios) {
				let scenario = confirmed_scenarios[i];
				confirmed_scenario_ids_map[scenario.id] = true;
			}

			for (let i in modified_scenarios) {
				let scenario = modified_scenarios[i]
				if (confirmed_scenario_ids_map[scenario.id]) {
					// Todo - handle conflicts if edited connector is already present
					scenario.cam_applicator_ids = scenario.cam_applicator_ids.filter(x => x !== chosen_connector)
					// scenario.cam_applicator_ids.push(edited_connector_id);
					scenario.cam_applicator_ids = scenario.cam_applicator_ids.concat(edited_connector_id);
				}
			}

			final_connector_scenario_data.scenarios = modified_scenarios;
			await updateConnectorSetDetails(JSON.stringify(final_connector_scenario_data))
			handle_close()

		} else {
			set_step((prev) => prev + 1)
		}
	}
	const onClickBack = () => {
		set_step((prev) => prev - 1)
	}

	let disabled_next_message = onClickNextDisabled();
	let disabled_back_message = onClickBackDisabled();

	useEffect(() => {
		set_eligible_connectors(getAllEligibleCamApplicators(selected_scenarios, all_connectors))
	}, [selected_scenarios, modal_json]);


	return (
		<Modal centered size='xl' isOpen={open} /* toggle={handle_close} */>
			<IModalHeader toggle={handle_close}>Add Connector</IModalHeader>
			<IModalBody style={{ padding: '0px', height: '550px', maxHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
				<div style={{ width: '100%', borderBottom: '1px solid #C5C7CF', borderTop: '1px solid #C5C7CF', display: 'flex', justifyContent: 'center', gap: '24px', padding: '12px' }}>
					<Steps current={step} items={steps} />
				</div>
				<div style={{ flex: 1, minHeight: '0px', overflow: 'auto', padding: '16px' }}>
					{
						step === 0 ?
							<ChooseConnector connector_type={get_commpon_connector_type(selected_scenarios)} connectors={eligible_connectors} set_connectors={set_eligible_connectors} chosenConnector={chosen_connector} setChosenConnector={set_chosen_connector} show_tabs={true} allow_create={true} idOnly={false} isMulti/>
							: ''
					}
					{/* {
						step === 1 ?
							<ConfirmScenarios selected_scenarios={selected_scenarios} all_scenarios={all_applicable_scenarios} actionMessage={"Choose where to remove this connector"} confirmedScenario={confirmed_scenario} setConfirmedScenario={set_confirmed_scenario} />
							: ''
					} */}
					{
						step === 1 ?
							<VerifyAndAdd new_connector={chosen_connector} scenarios={getConfirmedScenario(confirmed_scenario, selected_scenarios, all_applicable_scenarios)} panel_type_options={modal_json.panel_type_options}/>
							: ''
					}
				</div>
			</IModalBody>
			<IModalFooter>
				{step > 0 ? <Button disabled={disabled_back_message} className='secondary_button_default' onClick={onClickBack}>Back</Button> : ''}
				<Tooltip title={disabled_next_message}>
					<div>
						<Button disabled={disabled_next_message} className='primary_button_default' onClick={onClickNext}>{step === 1?"Confirm & Apply":"Next"}</Button>
					</div>
				</Tooltip>
			</IModalFooter>
		</Modal>
	)
}
